import { gql } from '@apollo/client';

export const REMOVE_LEAD = gql`
  mutation Mutation($input: CrmRemoveLeadInput!) {
    crm {
      lead {
        removeLead(input: $input) {
          success
        }
      }
    }
  }
`;

export const TRANSFER_LEAD = gql`
  mutation TransferLead($input: CrmTransferLeadInput!) {
    crm {
      lead {
        transferLead(input: $input) {
          leadId
          success
        }
      }
    }
  }
`;

export const ADD_LEAD = `
  mutation Mutation($input: CrmCreateLeadInput!) {
      crm {
        lead {
          createLead(input: $input) {
            success
            result {
              id
              name
              email
              contactNo
              roofTypeId
              zipCode
              hasTaskSequence
              interestTypeId
              leadFormId
              assignedToId
              assignedById
              createdAt
              isContacted
              addressId
              address {
                id
                streetNo
                streetName
                city
                state
                zipCode
                country
                lat
                lng
                createdAt
              }
            }
          }
        }
      }
    }
`;

export const CREATE_LEAD = gql`
  mutation CreateLead($input: CrmCreateLeadInput!) {
    crm {
      lead {
        createLead(input: $input) {
          success
          leadId
        }
      }
    }
  }
`;

export const CREATE_LEAD_WITH_MULTIPLE_INTERESTS = gql`
  mutation CreateLead($input: CrmCreateLeadWithMultipleInterestsInput!) {
    crm {
      lead {
        createLeadWithMultipleInterests(input: $input) {
          success
          leadId
        }
      }
    }
  }
`;

export const UPDATE_LEAD = gql`
  mutation Mutation($input: CrmUpdateLeadInput!) {
    crm {
      lead {
        updateLead(input: $input) {
          lead {
            address {
              city
              country
              createdAt
              id
              state
              zipCode
              streetNo
              streetName
              lat
              lng
            }
          }
        }
      }
    }
  }
`;
export const SEND_SMS = gql`
  mutation Mutation($input: CrmStartConversationInput!) {
    crm {
      twilio {
        conversation {
          startConversation(input: $input) {
            success
          }
        }
      }
    }
  }
`;

export const SEND_EMAIL = gql`
  mutation Mutation($input: CrmEmailToLeadInput!) {
    crm {
      lead {
        emailToLead(input: $input) {
          success
          lead {
            id
          }
        }
      }
    }
  }
`;

export const SEND_NOTE = gql`
  mutation UpdateTaskSchedule($input: CrmUpdateTaskScheduleInput!) {
    crm {
      taskSchedule {
        updateTaskSchedule(input: $input) {
          success
        }
      }
    }
  }
`;

export const UPDATE_LEAD_REASSIGNMENT_STATUS = gql`
  mutation Mutation($input: CrmUpdateLeadReassignmentStatusInput!) {
    crm {
      lead {
        updateLeadReassignmentStatus(input: $input) {
          success
        }
      }
    }
  }
`;
