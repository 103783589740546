import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { DateFormatter, DateFormatType } from '../../../components/DateFormatter';
import { CrmCallPayload, mapCallPayloadCallerData } from '../../../types/crm/callPayload';
import EllipsisText from '../../../components/EllipsisText';

export interface VoicemailModalProps {
  open: boolean;
  onClose: () => void;
  data?: CrmCallPayload | undefined;
  param?: string | undefined;
}

const VoicemailDetailsGridItem = (props: { name: string; value: string | React.ReactNode }) => {
  return (
    <React.Fragment>
      <Grid item xs={5}>
        {props.name}
      </Grid>
      <Grid item xs={7}>
        {<Typography sx={{ color: '#4286de' }}>{props.value}</Typography>}
      </Grid>
    </React.Fragment>
  );
};

const MissedCallModal = (props: VoicemailModalProps) => {
  const { open, onClose, data, param } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="missed-call-details"
      aria-describedby="missed-call-details"
      fullWidth
    >
      <DialogTitle id={data?.sid!}>{'Missed Call Details'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {data ? (
            <>
              <VoicemailDetailsGridItem
                name={'From'}
                value={
                  <EllipsisText
                    content={
                      data && data?.callerData?.__typename === 'CrmUser'
                        ? `${mapCallPayloadCallerData(data?.callerData)?.userFirstName ?? ''} ${
                            mapCallPayloadCallerData(data?.callerData)?.userLastName ?? ''
                          } (${mapCallPayloadCallerData(data?.callerData)?.userContactNo ?? ''})`
                        : data && data?.callerData?.__typename === 'CrmLead'
                          ? `${mapCallPayloadCallerData(data?.callerData)?.leadName ?? ''} (${
                              mapCallPayloadCallerData(data?.callerData)?.leadContactNo ?? ''
                            })`
                          : `Unknown (${data && data?.caller})` ?? 'No data'
                    }
                  />
                }
              />
              <VoicemailDetailsGridItem name={'Call Sid'} value={data?.sid!} />
              <VoicemailDetailsGridItem
                name={'Date/Time'}
                value={DateFormatter({
                  date: data?.createdAt!.toString(),
                  dateFormatType: DateFormatType.LongDateWithTime,
                })}
              />
            </>
          ) : (
            <Grid item width={'100%'}>
              No missed call found with id "{param ?? param}".
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions dir={'rtl'}>
        <Grid container justifyContent="space-between">
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default MissedCallModal;
