import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  Input,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

type searchBarProps = {
  searchData: (searchValue: string | number, searchCriteria: string) => void;
};

const AvailableTwilioNumbersSearchBar = (props: searchBarProps) => {
  const { searchData } = props;
  const [searchValue, setSearchValue] = useState('');
  const [searchCriteria, setSearchCriteria] = useState<string>('areaCode');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [placeholder, setPlaceholder] = useState<string>('Search');

  const searchCriteriaOptions = [{ type: 'areaCode', displayName: 'Area Code', shortName: 'Area Code' }];

  useEffect(() => {
    setPlaceholder(
      searchCriteria
        ? `Search by ${searchCriteriaOptions.find(option => option.type === searchCriteria)?.displayName.toLowerCase()}`
        : 'Search',
    );
  }, [searchCriteria]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const handleSearchData = () => {
    searchData(searchValue, searchCriteria);
  };
  const handleKeyDown = (event: { key: string; preventDefault: () => void }) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearchData();
    }
  };

  const handleFieldChange = (event: any) => {
    setSearchCriteria(event.target.value);
  };

  const handleClear = () => {
    setSearchValue('');
    searchData('', searchCriteria);
  };

  useEffect(() => {
    if (searchCriteria === 'areaCode' && searchValue && isNaN(Number(searchValue))) {
      setErrorMessage('Please enter a valid number');
    } else {
      setErrorMessage('');
    }
  }, [handleInputChange, searchCriteria]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <FormControl variant="outlined" fullWidth>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #ccc',
            borderRadius: '4px',
            overflow: 'hidden',
            height: '3rem',
          }}
        >
          <TextField
            placeholder={placeholder}
            value={searchValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ marginLeft: '0.5rem' }} />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            variant="standard"
            style={{ flex: 1 }}
          />
          {searchValue && (
            <React.Fragment>
              <Button onClick={handleClear}>Clear</Button>
            </React.Fragment>
          )}
          <Button onClick={handleSearchData} disabled={!!errorMessage}>
            Search
          </Button>
          <FormControl variant="outlined" style={{ minWidth: '200px', margin: 0, marginLeft: 10 }}>
            <Select
              value={searchCriteria}
              onChange={handleFieldChange}
              input={<Input disableUnderline />}
              displayEmpty
              renderValue={selected => {
                return selected ? (
                  searchCriteriaOptions.find(option => option.type === selected)?.shortName
                ) : (
                  <em>Select Search Criteria</em>
                );
              }}
              variant="standard"
            >
              {searchCriteriaOptions.map(field => (
                <MenuItem key={field.type} value={field.type}>
                  <ListItemText primary={field.displayName} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </FormControl>
      {!!errorMessage && (
        <Typography variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};

export default AvailableTwilioNumbersSearchBar;
