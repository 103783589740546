import React from 'react';
import { TooltipProps } from '@mui/material/Tooltip';
import { Tooltip } from '@mui/material';

export interface TooltipWithOffsetProps extends TooltipProps {
  offset: [number, number];
}

export const TooltipWithOffset: React.FC<TooltipWithOffsetProps> = ({ offset, children, ...props }) => {
  return (
    <Tooltip
      arrow
      {...props}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset,
              },
            },
          ],
        },
      }}
    >
      {children}
    </Tooltip>
  );
};
