import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { Grid, IconButton, Tooltip, TooltipProps, Typography } from '@mui/material';
import { Icon } from 'react-feather';
import { SvgIconComponent } from '@mui/icons-material';

export enum ProfileIconSizeType {
  SMALL,
  MEDIUM,
  LARGE,
  XL,
}

type IconTypeButtonTypes = {
  onClick?: () => void;
  disabled?: boolean;
  color?: string;
};

export type IconType = {
  DisplayIcon: Icon | SvgIconComponent;
  label?: string;
  size?: ProfileIconSizeType.SMALL | ProfileIconSizeType.MEDIUM | ProfileIconSizeType.LARGE | ProfileIconSizeType.XL;
  iconTypeButton?: IconTypeButtonTypes;
  toolTipProps?: Omit<TooltipProps, 'children'>;
};

/**
 * Renders a profile icon for a contact.
 *
 * @component
 * @param {IconType} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const ContactProfileIcon = (props: IconType) => {
  const { DisplayIcon, label, iconTypeButton, toolTipProps } = props;
  const [iconSize, setIconSize] = useState<number>(20);
  const [avatarSize, setAvatarSize] = useState<number>(40);

  useEffect(() => {
    if (props.size === ProfileIconSizeType.SMALL) {
      setIconSize(20);
      setAvatarSize(40);
    } else if (props.size === ProfileIconSizeType.MEDIUM) {
      setIconSize(27);
      setAvatarSize(50);
    } else if (props.size === ProfileIconSizeType.LARGE) {
      setIconSize(40);
      setAvatarSize(60);
    } else if (props.size === ProfileIconSizeType.XL) {
      setIconSize(80);
      setAvatarSize(100);
    }
  }, [props.size]);

  return (
    <Grid item textAlign={'center'}>
      <Avatar
        sx={{
          backgroundColor: iconTypeButton?.color ? iconTypeButton.color : '#0000000a',
          width: avatarSize,
          height: avatarSize,
        }}
      >
        {iconTypeButton?.onClick ? (
          <Tooltip title={toolTipProps?.title ?? ''} {...toolTipProps}>
            <span>
              <IconButton onClick={iconTypeButton?.onClick} disabled={iconTypeButton?.disabled}>
                <DisplayIcon
                  style={{
                    color: iconTypeButton?.color ? 'white' : '#6F6F6F',
                    width: iconSize,
                    height: iconSize,
                  }}
                />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <DisplayIcon
            style={{
              color: '#6F6F6F',
              width: iconSize,
              height: iconSize,
            }}
          />
        )}
      </Avatar>
      {label && <Typography>{label}</Typography>}
    </Grid>
  );
};

export default ContactProfileIcon;
