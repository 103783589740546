import React from 'react';
import { Grid, Modal } from '@mui/material';
import { TaskModalFooter } from '../../../task/components/TaskModalFooter';
import { ActivityType } from '../../../task/taskTypes';
import { VoiceCallComponent } from '../../../task/components/VoiceCallComponent';
import { CrmUser } from '../../../../types/crm/user';
import { CrmLead } from '../../../../types/crm/lead';
import { TaskContactInformation } from '../../../task/components/TaskContactInformation';
import { TaskLeadInformation } from '../../../task/components/TaskLeadInformation';
import EmailTaskForm from '../../../task/components/EmailTaskForm';
import SmsTaskForm from '../../../task/components/SmsTaskForm';

export interface ContactLeadModalProps {
  openModal: boolean;

  handleActivitySelection(activity: ActivityType): void;

  selectedActivity: ActivityType;
  onClose: () => void;
  userIdentity?: CrmUser;
  currentLead?: CrmLead;
}

const ContactLeadModal = (props: ContactLeadModalProps) => {
  const { openModal, handleActivitySelection, onClose, selectedActivity, userIdentity, currentLead } = props;

  const modalTheme = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #8888',
    borderRadius: 2,
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 0,
    padding: 0,
    alignItems: 'stretch',
    boxSizing: 'border-box',
  };

  return (
    <Modal
      open={openModal}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <React.Fragment>
        <Grid sx={modalTheme}>
          {selectedActivity && selectedActivity === ActivityType.VOICE && (
            <div>
              {userIdentity && (
                <VoiceCallComponent
                  contactNumber={currentLead?.contactNo!}
                  userMakingCall={userIdentity}
                  fromContactProfile={true}
                />
              )}
            </div>
          )}
          <Grid
            container
            xs={12}
            sx={{
              backgroundColor: '#F7F9FC',
              overflow: 'auto',
              p: 3,
            }}
          >
            <Grid item xs={12}>
              <TaskContactInformation currentLead={currentLead!} />
              <TaskLeadInformation currentLead={currentLead!} />
            </Grid>
            <Grid item xs={12}>
              {(() => {
                switch (selectedActivity) {
                  case ActivityType.EMAIL:
                    return <EmailTaskForm data={currentLead!} fromContactProfile={true} />;
                  case ActivityType.SMS:
                    return <SmsTaskForm data={currentLead!} fromContactProfile={true} />;
                  default:
                    return;
                }
              })()}
            </Grid>
          </Grid>
          <TaskModalFooter
            handleActivitySelection={handleActivitySelection}
            currentUser={userIdentity}
            selectedActivity={selectedActivity}
            onClose={onClose}
          />
        </Grid>
      </React.Fragment>
    </Modal>
  );
};

export default ContactLeadModal;
