import React, { useContext, useEffect, useState } from 'react';
import { Box, Breadcrumbs as MuiBreadcrumbs, Divider as MuiDivider, Grid, Link, Typography } from '@mui/material';
import { spacing } from '@mui/system';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { CurrentUserContext } from '../../../contexts/CurrentUserContext';
import { SnackbarProvider } from 'notistack';
import { UserProfileContext } from '../../../contexts/userProfile/UserProfileContext';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { ProvidersEnum, UserIdentities } from '../../types/userIdentities';
import NucleusLoader from '../../NucleusLoader';
import { Outlet } from 'react-router-dom';
import UserSettings from './userProfileLeftSideDetails/UserSettings';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);

interface UserProfileProps {
  children?: React.ReactNode;
}

const UserProfile: React.FC<UserProfileProps> = ({ children }) => {
  const currentUser = useContext(CurrentUserContext);

  const [allowResetPassword, setAllowResetPassword] = useState(false);
  const [isFetchingAttributes, setIsFetchingAttributes] = useState(true);

  const fetchAttributes = async () => {
    try {
      const attributes = await fetchUserAttributes();

      if (!attributes) {
        setAllowResetPassword(true);
        setIsFetchingAttributes(false);
        return;
      }

      const identities = attributes['identities'] ? JSON.parse(attributes['identities']) : null;

      if (!identities) {
        setAllowResetPassword(true);
        setIsFetchingAttributes(false);
        return;
      }

      const providerName = (identities[0] as UserIdentities)?.providerName ?? '';

      if (providerName === ProvidersEnum.GOOGLE) {
        setAllowResetPassword(false);
      } else {
        setAllowResetPassword(true);
      }

      setIsFetchingAttributes(false);
    } catch (error) {
      setIsFetchingAttributes(false);
      return;
    }
  };

  useEffect(() => {
    fetchAttributes();
  }, []);

  if (!currentUser || isFetchingAttributes) {
    return <NucleusLoader />;
  }

  return (
    <UserProfileContext.Provider
      value={{
        passwordAndSecurityTypes: {
          allowResetPassword: allowResetPassword,
        },
      }}
    >
      <SnackbarProvider maxSnack={3} hideIconVariant>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box>
            <Typography variant="h3" gutterBottom display="inline">
              Settings
            </Typography>
            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <Link component={NavLink} to="/dashboard/default">
                {currentUser.firstName} {currentUser.lastName}
              </Link>
              <Typography>Settings</Typography>
              <Typography>User Management</Typography>
              <Typography>User Profile</Typography>
            </Breadcrumbs>
          </Box>
          <Divider my={4} />
          <Box sx={{ flex: 1, overflow: 'auto' }}>
            <Grid container spacing={3} sx={{ height: '100%' }}>
              {/* Left Side */}
              <Grid item xs={12} md={4}>
                <UserSettings />
              </Grid>
              {/* Right Side */}
              <Grid item xs={12} md={8} sx={{ height: '100%' }}>
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    background: 'white',
                    borderRadius: '0.375rem',
                    p: 2,
                    overflowY: 'auto',
                  }}
                >
                  {children}
                  <Outlet />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </SnackbarProvider>
    </UserProfileContext.Provider>
  );
};

export default UserProfile;
