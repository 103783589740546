import React from 'react';
import { Box, Divider as MuiDivider, List, Typography } from '@mui/material';
import styled from '@emotion/styled';
import SidebarNavSection, { Title as SideNavSectionTitle } from '../../../../components/sidebar/SidebarNavSection';
import { SidebarContext, SidebarContextTypes } from '../../../../contexts/sidebar/SidebarContext';
import { Badge, Item, Title as SidebarNavListItemTitle } from '../../../../components/sidebar/SidebarNavListItem';
import { navItemsAdmin as adminItems, navItemsUser as userItems } from '../../../layouts/UserProfileItems';

function UserSettings() {
  const Items = styled.div`
    padding-top: ${props => props.theme.spacing(2.5)};
    padding-bottom: ${props => props.theme.spacing(2.5)};
  `;

  const CustomSideNavSectionTitle = styled(SideNavSectionTitle)`
    color: #6f6f6f;
    padding-top: 0;
    text-transform: capitalize;
    opacity: 1;
  `;

  const CustomItem = styled(Item)`
    border-radius: 0.313rem;
    svg {
      color: #6f6f6f;
      font-size: 1.5rem;
      width: 1.5rem;
      height: 1.5rem;
      opacity: 0.7;
    }
    &:hover {
      background: #1e88e533;
      color: #6f6f6f;
    }

    &.${props => props.activeclassname} {
      background-color: #1e88e533;
      span {
        color: #6f6f6f;
      }
    }
  `;

  const CustomSidebarNavListItemTitle = styled(SidebarNavListItemTitle)`
    span {
      color: #6f6f6f;
    }
  `;

  const CustomBadge = styled(Badge)`
    span.MuiChip-label,
    span.MuiChip-label:hover {
      color: #6f6f6f;
    }
  `;

  const sidebarContextTypes: SidebarContextTypes = {
    sidebarNavSection: {
      sidebarNavSectionComponentTheme: {
        Title: CustomSideNavSectionTitle,
      },
    },
    sidebarNavListItem: {
      sidebarNavListItemComponentTheme: {
        Item: CustomItem,
        Title: CustomSidebarNavListItemTitle,
        Badge: CustomBadge,
      },
    },
  };

  return (
    <React.Fragment>
      <SidebarContext.Provider value={sidebarContextTypes}>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Box sx={{ background: '#ffffff', borderRadius: '0.375rem', p: 5 }}>
            <Typography variant="h6" gutterBottom display="inline">
              User Settings
            </Typography>

            <MuiDivider />

            <List disablePadding>
              <Items>
                {userItems &&
                  userItems.map(item => (
                    <SidebarNavSection component="div" key={item.title} pages={item.pages} title={item.title} />
                  ))}
              </Items>
            </List>
          </Box>
          <Box sx={{ mt: 5, background: '#ffffff', borderRadius: '0.375rem', p: 5 }}>
            <Typography variant="h6" gutterBottom display="inline">
              Admin Settings
            </Typography>

            <MuiDivider />

            <List disablePadding>
              <Items>
                {adminItems &&
                  adminItems.map(item => (
                    <SidebarNavSection component="div" key={item.title} pages={item.pages} title={item.title} />
                  ))}
              </Items>
            </List>
          </Box>
        </Box>
      </SidebarContext.Provider>
    </React.Fragment>
  );
}

export default UserSettings;
