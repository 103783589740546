import { Card, CardContent, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { CrmLead } from '../../../types/crm/lead';

const TaskLeadInformationGridItem = (props: { name: string; value: string }) => {
  return (
    <React.Fragment>
      <Grid item xs={5}>
        {props.name}
      </Grid>
      <Grid item xs={7}>
        <Typography sx={{ color: '#4286de' }}>{props.value}</Typography>
      </Grid>
    </React.Fragment>
  );
};

export const TaskLeadInformation = (props: { currentLead: CrmLead }) => {
  const { currentLead } = props;
  const source = currentLead?.assignedToUser
    ? `${currentLead.assignedToUser.firstName} ${currentLead.assignedToUser.lastName}`
    : currentLead?.leadForm?.platform ?? 'None';

  return (
    <Card style={{ borderRadius: '0px', position: 'relative', marginBottom: '1rem' }}>
      <CardContent style={{ padding: '0' }}>
        <Box sx={{ padding: '1.5rem' }}>
          <Typography variant={'h4'}>Lead Information</Typography>
          <Grid container sx={{ my: '0rem' }} spacing={3}>
            <TaskLeadInformationGridItem name={'Source'} value={source} />
            <TaskLeadInformationGridItem name={'Interest'} value={currentLead?.interestType?.interestTypeName} />
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};
