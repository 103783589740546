import React, { useContext, useEffect, useState } from 'react';
// import ReactQuill from 'react-quill';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import { Formik, FormikValues } from 'formik';
import { Alert, Box, Button, Grid } from '@mui/material';
import { FormikTextField } from '../../../components/formElements/FormikTextField';
import { useMutation } from '@apollo/client';
import { SEND_EMAIL } from '../../../queries/crm/lead';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { TaskSchedulesRowType } from '../taskTypes';
import CollapsableCard from '../../../components/CollapsableCard';
import { CrmLead } from '../../../types/crm/lead';
import NucleusLoader from '../../../NucleusLoader';
import {
  ProfileActivityContext,
  ProfileActivityContextProps,
} from '../../contacts/profile/context/ProfileActivityContext';

interface EmailFieldFormikFieldTypes {
  body: string;
  subject: string;
}

const CustomAlert = styled(Alert)`
  .MuiAlert-message {
    width: 100%;
    text-align: center;
  }
`;

export interface EmailTaskFormProps {
  data: CrmLead | undefined;
  taskScheduleRow?: TaskSchedulesRowType;
  fromContactProfile?: boolean;
}

const CKEditorWrapper = styled.div`
  .ck-editor__editable {
    min-height: 300px;
  }
`;
const EmailTaskForm = (props: EmailTaskFormProps) => {
  const { data, taskScheduleRow, fromContactProfile } = props;
  const { setInitiateActivity } = useContext(ProfileActivityContext) as ProfileActivityContextProps;
  const [sendEmailLead] = useMutation(SEND_EMAIL);
  const [contentValue, setContentValue] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  const EmailInitialValues = {
    body: '',
    subject: '',
    submit: '',
  };
  // Validation schema using Yup
  const EmailValidationSchema = Yup.object().shape({
    subject: Yup.string().required('Subject is required'),
    body: Yup.string().required('Body is required'),
  });

  useEffect(() => {
    (async () => {
      if (showAlert) {
        await delay(5000);
        setShowAlert(false);
      }
    })();
  }, [showAlert]);

  const handleSubmitEmail = async (
    values: EmailFieldFormikFieldTypes,
    { resetForm, setErrors, setStatus, setSubmitting }: FormikValues,
  ) => {
    try {
      if (true) {
        let input = {};
        if (taskScheduleRow) {
          input = {
            body: values.body,
            leadId: data?.id,
            subject: values.subject,
            bindActionToTask: {
              taskId: taskScheduleRow?.id,
            },
          };
        } else {
          input = {
            body: values.body,
            leadId: data?.id,
            subject: values.subject,
          };
        }
        setStatus({ sent: false });
        await sendEmailLead({
          variables: {
            input: input,
          },
        });
        resetForm();
        setContentValue('');
        setErrors({ submit: '' });
        setStatus({ sent: true });
        setSubmitting(false);
        setShowAlert(true);
        if (fromContactProfile) {
          setInitiateActivity(true);
        }
        return;
      }
    } catch (error) {
      setShowAlert(true);
      setStatus({ sent: false });
      setErrors({ submit: 'An error occurred while sending Email' });
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <CollapsableCard
        subTitle={'Create Email'}
        body={
          <Formik
            initialValues={EmailInitialValues}
            validationSchema={EmailValidationSchema}
            onSubmit={handleSubmitEmail}
          >
            {({
              errors,
              isSubmitting,
              touched,
              values,
              status,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              resetForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} flexDirection={'column'}>
                  <Grid item>
                    <FormikTextField
                      field="subject"
                      label="Subject"
                      values={values}
                      touched={touched}
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </Grid>
                  <Grid item marginY={2}>
                    <CKEditorWrapper>
                      <CKEditor
                        editor={ClassicEditor}
                        data={contentValue}
                        config={{
                          toolbar: [
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            'link',
                            'bulletedList',
                            'numberedList',
                            'blockQuote',
                            '|',
                            'undo',
                            'redo',
                          ],
                        }}
                        onChange={(_event, editor) => {
                          const content = editor.getData();
                          setContentValue(content);
                          setFieldValue('body', content);
                        }}
                      />
                    </CKEditorWrapper>
                  </Grid>
                  <Grid item marginTop={2}>
                    <Box display="flex" flexDirection="row" alignItems="center" gap={3} justifyContent="space-between">
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          resetForm();
                          setContentValue('');
                        }}
                        disabled={isSubmitting || values.subject.trim() === '' || contentValue.trim() === ''}
                      >
                        Clear
                      </Button>
                      {showAlert && status && status.sent && (
                        <CustomAlert
                          severity="success"
                          sx={{ width: '100%', paddingBottom: '0px', paddingTop: '0px' }}
                          onClose={() => {
                            setShowAlert(false);
                          }}
                        >
                          Email sent
                        </CustomAlert>
                      )}
                      {showAlert && errors && errors.submit && (
                        <CustomAlert
                          severity="error"
                          sx={{ width: '100%', paddingBottom: '0px', paddingTop: '0px' }}
                          onClose={() => {
                            setShowAlert(false);
                          }}
                        >
                          {errors.submit}
                        </CustomAlert>
                      )}
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ whiteSpace: 'nowrap' }}
                        disabled={values.subject.trim() === '' || contentValue.trim() === ''}
                        endIcon={isSubmitting ? <NucleusLoader type={'circular'} size={20} color="inherit" /> : null}
                      >
                        {isSubmitting ? 'Sending Email' : 'Send Email'}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        }
      />
    </React.Fragment>
  );
};

export default EmailTaskForm;
