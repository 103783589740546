import React from 'react';
import { ReactNode } from 'react';
import { CallStatusType } from '../components/VoiceCallComponent';

export type CallStatusContextProps = {
  callStatusContext: CallStatusType;
  setCallStatusContext: (status: CallStatusType) => void;
  canEndTaskContext: boolean | undefined;
  setCanEndTaskContext: (canEndTask: boolean) => void;
};

export enum AMDType {
  Machine = 'machine_start',
  Human = 'human',
  Fax = 'fax',
  Unknown = 'unknown',
  Undefined = 'undefined',
}

export const CallStatusContext = React.createContext<CallStatusContextProps | undefined>(undefined);

export const CallStatusProvider = ({ children }: { children: ReactNode }) => {
  const [callStatusContext, setCallStatusContext] = React.useState<CallStatusType>(CallStatusType.Ready);
  const [canEndTaskContext, setCanEndTaskContext] = React.useState<boolean | undefined>(true);

  return (
    <CallStatusContext.Provider
      value={{ callStatusContext, setCallStatusContext, canEndTaskContext, setCanEndTaskContext }}
    >
      {children}
    </CallStatusContext.Provider>
  );
};

//AMD Context
export type AMDContextProps = {
  answeredByContext: AMDType;
  setAnsweredByContext: (status: AMDType) => void;
};

export const AMDContext = React.createContext<AMDContextProps | undefined>(undefined);

export const AMDProvider = ({ children }: { children: ReactNode }) => {
  const [answeredByContext, setAnsweredByContext] = React.useState<AMDType>(AMDType.Undefined);

  return <AMDContext.Provider value={{ answeredByContext, setAnsweredByContext }}>{children}</AMDContext.Provider>;
};
