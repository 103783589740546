import React from 'react';
import { ReactNode } from 'react';
import { TaskTypeNameType } from '../../../types/crm/taskType';

export type TaskFormType = {
  taskTypeFormContext: TaskTypeNameType;
  submitFormContext: boolean;
  canSubmit?: boolean;
};

export type CreatedTaskType = {
  taskType: TaskTypeNameType;
  taskTitle: string;
};

export type TaskDispositionType = {
  dispositionId: number | null;
  reason: string | null;
  isSubmitting: boolean;
};

export type TaskFormContextProps = {
  submitTaskFormContext: TaskFormType;
  setSubmitTaskFormContext: (form: TaskFormType) => void;
  isSubmittingTaskFormContext: boolean;
  setIsSubmittingTaskFormContext: (isSubmitting: boolean) => void;
  createdTaskContext: CreatedTaskType;
  setCreatedTaskContext: (taskCreated: CreatedTaskType) => void;
  submitTaskDispositionContext: TaskDispositionType;
  setSubmitTaskDispositionContext: (disposition: TaskDispositionType) => void;
};

export type TaskTypeToCreateContextProps = {
  taskToCreateContext: TaskTypeNameType;
  setTaskToCreateContext: (taskType: TaskTypeNameType) => void;
};

/**
 * Context for the task form.
 */
export const TaskFormContext = React.createContext<TaskFormContextProps>({} as TaskFormContextProps);

/**
 * Provides the context for the submit task form.
 *
 * @param children - The child components to be wrapped by the provider.
 */
export const TaskFormProvider = ({ children }: { children: ReactNode }) => {
  const [submitTaskFormContext, setSubmitTaskFormContext] = React.useState<TaskFormType>({
    taskTypeFormContext: TaskTypeNameType.DEFAULT,
    submitFormContext: false,
    canSubmit: false,
  });

  const [isSubmittingTaskFormContext, setIsSubmittingTaskFormContext] = React.useState<boolean>(false);

  const [createdTaskContext, setCreatedTaskContext] = React.useState<CreatedTaskType>({
    taskType: TaskTypeNameType.DEFAULT,
    taskTitle: '',
  });

  const [submitTaskDispositionContext, setSubmitTaskDispositionContext] = React.useState<TaskDispositionType>({
    dispositionId: null,
    reason: null,
    isSubmitting: false,
  });

  return (
    <TaskFormContext.Provider
      value={{
        submitTaskFormContext,
        setSubmitTaskFormContext,
        isSubmittingTaskFormContext,
        setIsSubmittingTaskFormContext,
        createdTaskContext,
        setCreatedTaskContext,
        submitTaskDispositionContext,
        setSubmitTaskDispositionContext,
      }}
    >
      {children}
    </TaskFormContext.Provider>
  );
};

/**
 * Context for the type of task to create.
 */
export const TaskToCreateContext = React.createContext<TaskTypeToCreateContextProps | null>(null);

/**
 * Provides the context for the task type to be created.
 * @param children - The child components to be wrapped by the provider.
 */
export const TaskToCreateProvider = ({ children }: { children: ReactNode }) => {
  const [taskToCreateContext, setTaskToCreateContext] = React.useState<TaskTypeNameType>(TaskTypeNameType.DEFAULT);

  return (
    <TaskToCreateContext.Provider value={{ taskToCreateContext, setTaskToCreateContext }}>
      {children}
    </TaskToCreateContext.Provider>
  );
};
