import React from 'react';
import { PhoneMissed, PhoneOutgoing, PhoneIncoming, Voicemail } from 'react-feather';

interface CallStatusIconProps {
  status: string;
  direction: string;
}

const CallStatusIcon: React.FC<CallStatusIconProps> = ({ status, direction }) => {
  if (status === 'completed') {
    return direction === 'inbound' ? <PhoneIncoming color="#28a745" /> : <PhoneOutgoing color="#28a745" />;
  }

  switch (status) {
    case 'voicemail':
      return <Voicemail color="#FFA500" />; // Orange for voicemail
    default:
      return <PhoneMissed color="#dc3545" />; // Red for missed calls or unknown statuses
  }
};

export default CallStatusIcon;
