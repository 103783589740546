import React from 'react';
import { Tooltip } from '@mui/material';
import { Calendar, CheckSquare, Edit3, Mail, MapPin, MessageSquare } from 'react-feather';
import { History } from '@mui/icons-material';
import { SmallButton } from '../TaskModalFooter';
import { ActivityType } from '../../taskTypes';
import { CrmUser } from '../../../../types/crm/user';

interface VoiceTaskModalFooterProps {
  handleActivitySelection(activity: ActivityType): void;
  selectedActivity: ActivityType;
  currentUser: CrmUser | undefined;
}

const VoiceTaskFooter = (props: VoiceTaskModalFooterProps) => {
  const { handleActivitySelection, selectedActivity, currentUser } = props;
  // const { callStatusContext } = useContext<CallStatusContextProps>(
  //   ProfileActivityContext as Context<CallStatusContextProps>,
  // );
  // const { answeredByContext } = useContext(AMDContext) as AMDContextProps;

  const handleClick = (action: ActivityType) => {
    return () => handleActivitySelection(action);
  };

  const handleActionState = (action: ActivityType) => {
    switch (action) {
      case ActivityType.SMS:
        return currentUser?.twilio?.projectedAddress ? false : true;
      case ActivityType.EMAIL:
        return currentUser?.twilio?.sendGridVerified ? false : true;
      default:
        return false;
    }

    // switch (action) {
    //   case 'contact':
    //   case 'task':
    //     return false;
    //   case 'sms':
    //   case 'note':
    //   case 'schedule':
    //   case 'email':
    //     switch (callStatusContext) {
    //       case CallStatusType.InProgress:
    //         return false;
    //       case CallStatusType.Completed:
    //         if (answeredByContext === 'human') {
    //           return false;
    //         }
    //         break;
    //       default:
    //         return true;
    //     }
    //     return true;
    // }
    // return undefined;
  };

  return (
    <React.Fragment>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
        <Tooltip title="Task">
          <span>
            <SmallButton
              disabled={handleActionState(ActivityType.TASK)}
              onClick={handleClick(ActivityType.TASK)}
              size="small"
            >
              <CheckSquare
                style={{
                  color: selectedActivity === ActivityType.TASK ? '#428ADF' : '#6F6F6F',
                  width: 20,
                  height: 20,
                }}
              />
            </SmallButton>
          </span>
        </Tooltip>
        <Tooltip title="Property Address">
          <span>
            {' '}
            <SmallButton
              disabled={handleActionState(ActivityType.ADDRESS)}
              onClick={handleClick(ActivityType.ADDRESS)}
              size="small"
            >
              <MapPin
                style={{
                  color: selectedActivity === ActivityType.ADDRESS ? '#428ADF' : '#6F6F6F',
                  width: 20,
                  height: 20,
                }}
              />
            </SmallButton>
          </span>
        </Tooltip>
        <Tooltip title="Schedule">
          <span>
            <SmallButton
              disabled={handleActionState(ActivityType.SCHEDULE)}
              onClick={handleClick(ActivityType.SCHEDULE)}
              size="small"
            >
              <Calendar
                style={{
                  color: selectedActivity === ActivityType.SCHEDULE ? '#428ADF' : '#6F6F6F',
                  width: 20,
                  height: 20,
                }}
              />
            </SmallButton>
          </span>
        </Tooltip>
        <Tooltip title={currentUser?.twilio?.projectedAddress ? 'Sms' : 'Your account cannot send sms'}>
          <span>
            <SmallButton
              disabled={handleActionState(ActivityType.SMS)}
              onClick={handleClick(ActivityType.SMS)}
              size="small"
            >
              <MessageSquare
                style={{ color: selectedActivity === ActivityType.SMS ? '#428ADF' : '#6F6F6F', width: 20, height: 20 }}
              />
            </SmallButton>
          </span>
        </Tooltip>
        <Tooltip title={currentUser?.twilio?.sendGridVerified ? 'Email' : 'Your SendGrid account is not verified'}>
          <span>
            <SmallButton
              disabled={handleActionState(ActivityType.EMAIL)}
              onClick={handleClick(ActivityType.EMAIL)}
              size="small"
            >
              <Mail
                style={{
                  color: selectedActivity === ActivityType.EMAIL ? '#428ADF' : '#6F6F6F',
                  width: 20,
                  height: 20,
                }}
              />
            </SmallButton>
          </span>
        </Tooltip>
        <Tooltip title="Note">
          <span>
            <SmallButton
              disabled={handleActionState(ActivityType.NOTE)}
              onClick={handleClick(ActivityType.NOTE)}
              size="small"
            >
              <Edit3
                style={{ color: selectedActivity === ActivityType.NOTE ? '#428ADF' : '#6F6F6F', width: 20, height: 20 }}
              />
            </SmallButton>
          </span>
        </Tooltip>
        <Tooltip title="History">
          <span>
            <SmallButton onClick={handleClick(ActivityType.HISTORY)} size="small">
              <History
                style={{
                  color: selectedActivity === ActivityType.HISTORY ? '#428ADF' : '#6F6F6F',
                  width: 20,
                  height: 20,
                }}
              />
            </SmallButton>
          </span>
        </Tooltip>
      </div>
    </React.Fragment>
  );
};

export default VoiceTaskFooter;
