import { gql } from '@apollo/client';

export const UPDATE_TASK_SCHEDULE = gql`
  mutation UpdateTaskSchedule($input: CrmUpdateTaskScheduleInput!) {
    crm {
      taskSchedule {
        updateTaskSchedule(input: $input) {
          success
        }
      }
    }
  }
`;

export const CREATE_TASK_SCHEDULE = gql`
  mutation CreateTaskSchedule($input: CrmCreateTaskScheduleInput!) {
    crm {
      taskSchedule {
        createTaskSchedule(input: $input) {
          success
        }
      }
    }
  }
`;
