import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

export interface AlertMessageProps {
  setShow: (show: boolean) => void;
  alertTitle?: string;
  message: string | React.ReactNode;
  textAlignment?: 'center' | 'left' | 'right';
  severity: 'success' | 'info' | 'warning' | 'error' | undefined;
  variant?: 'filled' | 'outlined' | 'standard';
  icon?: boolean | React.ReactNode;
}

export const alertTheme = {
  width: '100%',
  mt: 2,
  mb: 2,
  '& .MuiAlert-message': {
    width: '100%',
  },
  '& .MuiAlert-icon': {
    alignItems: 'center',
  },
  '& .MuiAlert-action': {
    alignItems: 'center',
  },
};

const AlertMessage = (props: AlertMessageProps) => {
  const { setShow, message, alertTitle, severity, variant, textAlignment } = props;

  return (
    <React.Fragment>
      <Alert
        variant={variant ?? 'standard'}
        severity={severity ?? 'info'}
        sx={{ ...alertTheme, textAlign: textAlignment ?? 'left' }}
        onClose={() => {
          setShow(false);
        }}
      >
        {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
        {message}
      </Alert>
    </React.Fragment>
  );
};

export default AlertMessage;
