import { gql } from '@apollo/client';

export const MARK_NOTIFICATION = gql`
  mutation MarkNotification($input: CrmMarkNotificationStateInput!) {
    crm {
      userOption {
        markNotification(input: $input) {
          success
        }
      }
    }
  }
`;
