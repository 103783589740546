import React, { useState } from 'react';
import { GoogleMap, Libraries, Marker, useLoadScript } from '@react-google-maps/api';
import { GoogleMapEditConfirmType } from '../taskTypes';
import { AddressResult, getMapAddress } from '../../../types/map';
import { Typography } from '@mui/material';

const libraries: Libraries | undefined = ['places'];

type StyleTypes = {
  height?: number | string;
  width?: number | string;
  marginTop?: number | string;
};

export type GoogleMapPositionTypes = {
  lat: number;
  lng: number;
};

type MapTypeId = 'hybrid';
type GestureHandling = 'none';

type OptionTypes = {
  fullscreenControl?: boolean;
  mapTypeControl?: boolean;
  mapTypeId?: MapTypeId;
  scaleControl?: boolean;
  scrollwheel?: boolean;
  streetViewControl?: boolean;
  gestureHandling?: GestureHandling;
};

export interface LeadMapProps {
  position: GoogleMapPositionTypes;
  useMarker?: boolean;
  mapContainerStyle?: StyleTypes;
  handleOnMapOnChange?: (map: google.maps.Map | null) => void;
  handleOnMapMarkerOnChange?: (mapMarker: google.maps.Marker | null) => void;
  handleDragEnd?: (e: google.maps.MapMouseEvent) => void;
  options?: OptionTypes;
  zoom?: number;
  buttonState?: GoogleMapEditConfirmType;
  showAddressLabel?: boolean;
  isMarkerdraggable?: boolean;
}

const LeadMap = (props: LeadMapProps) => {
  const {
    position,
    useMarker,
    mapContainerStyle = {
      height: '100%',
      width: '100%',
    },
    handleOnMapOnChange = () => {},
    handleOnMapMarkerOnChange = () => {},
    handleDragEnd = () => {},
    options: propsOptions,
    zoom = 16,
    buttonState,
    showAddressLabel = false,
    isMarkerdraggable = true,
  } = props;

  const [address, setAddress] = useState('');
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [mapMarker, setMapMarker] = useState<google.maps.Marker | null>(null);

  const defaultOptions: OptionTypes = {
    fullscreenControl: false,
    mapTypeControl: false,
    mapTypeId: 'hybrid',
    scaleControl: true,
    scrollwheel: false,
    streetViewControl: true,
  };

  const options = {
    ...defaultOptions,
    ...propsOptions,
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCmxw8d96uQwl80ba-ka1Vdj9H9JHddwL0',
    libraries: libraries,
  });

  const getAddress = (latLng: google.maps.LatLng | undefined) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK' && results && results[0]) {
        const addressResult: AddressResult = getMapAddress(results[0]);
        const formattedAddress = `${addressResult.streetNumber} ${addressResult.streetName} ${addressResult.city} ${addressResult.state} ${addressResult.zipCode} ${addressResult.country}`;
        setAddress(formattedAddress);
      }
    });
  };

  const onMapLoad = (mapInit: google.maps.Map) => {
    handleOnMapOnChange(mapInit);
    setMap(mapInit);
    getAddress(mapInit.getCenter());
  };

  const onMapUnmount = () => {
    handleOnMapOnChange(null);
  };

  const onMapMarkerLoad = (mapMarkerInit: google.maps.Marker) => {
    handleOnMapMarkerOnChange(mapMarkerInit);
    setMapMarker(mapMarkerInit);
  };

  const onMapMarkerUnmount = () => {
    handleOnMapMarkerOnChange(null);
  };

  const onCenterChanged = () => {
    if (map) {
      getAddress(map.getCenter());
    }
  };

  const onDragEnd = (e: google.maps.MapMouseEvent) => {
    if (mapMarker && e && e.latLng) {
      getAddress(e.latLng);
      handleDragEnd(e);
    }
  };

  if (loadError) return <div>Error loading maps. Please try again later.</div>;
  if (!isLoaded) return <div>Loading maps...</div>;

  return (
    <React.Fragment>
      <GoogleMap
        onLoad={onMapLoad}
        onUnmount={onMapUnmount}
        mapContainerStyle={mapContainerStyle}
        center={position}
        zoom={zoom}
        options={options}
        onCenterChanged={onCenterChanged}
      >
        {useMarker && (
          <Marker
            onLoad={onMapMarkerLoad}
            onUnmount={onMapMarkerUnmount}
            position={position}
            draggable={buttonState === GoogleMapEditConfirmType.CONFIRM || isMarkerdraggable}
            onDragEnd={onDragEnd}
          />
        )}
      </GoogleMap>
      {showAddressLabel ? (
        <Typography variant="body2" gutterBottom mt={3}>
          Property Address Indicated: {address ? address : 'No address'}
        </Typography>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default LeadMap;
