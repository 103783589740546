import React, { useEffect, useState } from 'react';
import { IdentityTwilioNumbers } from '../../../../types/identity/admin/twilio';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { RootState } from '../../../../../redux/store';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_AVAILABLE_TWILIO_NUMBERS } from '../../../../../common/graphQL/admin/twilio/queries';
import {
  setAvailableTwilioNumbersData,
  setIsDataLoaded,
} from '../../../../../redux/twilio/availableTwilioNumbersSlice';
import { DataGrid, GridAlignment, GridCellParams, GridCloseIcon, GridColDef } from '@mui/x-data-grid';
import { Box, Button, Card, CardContent, CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
import NucleusPage from '../../../../components/NucleusPage';
import CustomNoRowsOverlay from '../../../../components/advanceSearchGrid/CustomNoRowsOverlay';
import { Numbers, Refresh, Phone, Sms, Mms, Check } from '@mui/icons-material';
import { blue, grey } from '@mui/material/colors';
import AvailableTwilioNumbersSearchBar from '../components/AvailableTwilioNumbersSearchBar';
import { TooltipWithOffset } from '../utils/utils';
import { BUY_TWILIO_NUMBER } from '../../../../../common/graphQL/admin/twilio/mutations';
import {
  IdentityBuyAccountNumberResult,
  IdentityPurchasedPhoneNumber,
} from '../../../../types/identity/admin/twilio/phoneNumbers';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { NotificationType } from '../../../../types/conversation';
import { HasOnePermission } from '../../../../utils/users/checkUserPermissions';

export interface TwilioNumbersTableProps {
  height?: string | number;
  onBuySuccess?: (number: IdentityPurchasedPhoneNumber) => void;
}

export const TwilioNumbersTable = (props: TwilioNumbersTableProps) => {
  const { height, onBuySuccess } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const isDataLoaded = useAppSelector((state: RootState) => state.availableTwilioNumbers.isDataLoaded);
  const availableTwilioNumbersData = useAppSelector((state: RootState) => state.availableTwilioNumbers.data);
  const [searchString, setSearchString] = useState<string | number>('');
  const [searchCriteria, setSearchCriteria] = useState<string>('areaCode');

  const handleNotification = ({ message, variant }: NotificationType) => {
    enqueueSnackbar(message, {
      variant: variant,
      persist: false,
      action: key => (
        <IconButton title="Close error" onClick={() => closeSnackbar(key)}>
          <GridCloseIcon color="disabled" />
        </IconButton>
      ),
    });
  };

  const [
    getAvailableTwilioNumbers,
    { data: availableTwilioNumbersResults, loading: isLoadingAvailableTwilioNumbers, error: errorResults },
  ] = useLazyQuery<IdentityTwilioNumbers>(GET_AVAILABLE_TWILIO_NUMBERS, {
    variables: {
      filter: searchString !== '' ? { [searchCriteria]: searchString } : {},
    },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      dispatch(setAvailableTwilioNumbersData([]));
      dispatch(setIsDataLoaded(true));
    },
  });

  useEffect(() => {
    getAvailableTwilioNumbers();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isDataLoaded) {
      getAvailableTwilioNumbers().then(result => {
        const availNumbers = result?.data?.identity?.admin?.twilio?.availableNumbers ?? [];
        if (availNumbers.length > 0) {
          const updatedData = availNumbers.map((row, index) => ({
            ...row,
            id: row.phoneNumber || index, // Assign unique id here
          }));
          dispatch(setAvailableTwilioNumbersData(updatedData));
        }
        dispatch(setIsDataLoaded(true));
      });
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (availableTwilioNumbersResults && availableTwilioNumbersResults?.identity?.admin?.twilio?.availableNumbers) {
      const availNumbers = availableTwilioNumbersResults?.identity?.admin?.twilio?.availableNumbers ?? [];
      if (availNumbers.length > 0) {
        const updatedData = availNumbers.map((row, index) => ({
          ...row,
          id: row.phoneNumber || index, // Assign unique id here
        }));
        dispatch(setIsDataLoaded(true));
        dispatch(setAvailableTwilioNumbersData(updatedData));
      } else {
        dispatch(setAvailableTwilioNumbersData([]));
        dispatch(setIsDataLoaded(true));
      }
    }
  }, [availableTwilioNumbersResults, dispatch]);

  const searchNumbers = (searchValue: string | number, selectedField: string) => {
    setSearchString(searchValue);
    setSearchCriteria(selectedField);
  };

  const [buyNumberLoadingState, setBuyNumberLoadingState] = useState<Record<string, boolean>>({});
  const [boughtNumbers, setBoughtNumbers] = useState<Record<string, boolean>>({});

  const [buyTwilioNumber, { loading: isLoadingBuyNumber }] =
    useMutation<IdentityBuyAccountNumberResult>(BUY_TWILIO_NUMBER);

  const handleBuyNumber = async (phoneNumber: string) => {
    // Set loading state for the current row
    setBuyNumberLoadingState(prevState => ({
      ...prevState,
      [phoneNumber]: true,
    }));

    try {
      // Perform the buy operation
      const { data } = await buyTwilioNumber({
        variables: {
          input: {
            number: phoneNumber,
          },
        },
      });

      const newNumber = data?.identity?.admin?.twilio?.buyAccountNumber?.accounts[0];

      // Mark the number as bought
      setBoughtNumbers(prevState => ({
        ...prevState,
        [phoneNumber]: true,
      }));
      onBuySuccess?.(newNumber ?? ({} as unknown as IdentityPurchasedPhoneNumber));
      handleNotification({ message: `The number ${phoneNumber} was successfully purchased.`, variant: 'success' });
    } catch (error) {
      handleNotification({ message: `Failed to purchase the number ${phoneNumber}.`, variant: 'error' });
    } finally {
      // Reset loading state for the current row
      setBuyNumberLoadingState(prevState => ({
        ...prevState,
        [phoneNumber]: false,
      }));
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'phoneNumber',
      headerName: 'Number',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      minWidth: 300,
      renderCell: (params: GridCellParams) => {
        return (
          <Grid item>
            <Typography variant={'button'} mb={'0.2rem'}>
              {params.row.phoneNumber}
            </Typography>
            <Typography variant={'body2'} color={grey[700]}>{`${params.row.locality ?? ''} ${
              params.row.locality ? ', ' : ''
            } ${params.row.isoCountry === params.row.region ? '' : params.row.region ?? ''} ${
              params.row.isoCountry ?? ''
            }`}</Typography>
          </Grid>
        );
      },
    },
    {
      field: 'capabilities',
      headerName: 'Capabilities',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      minWidth: 300,
      renderCell: (params: GridCellParams) => {
        return (
          <Stack direction={'row'} spacing={{ xs: 1, sm: 2, md: 6 }} color={blue[500]}>
            <TooltipWithOffset title={'Voice'} offset={[0, -8]}>
              <Typography variant={'body1'}>{params.row.capabilities.voice ? <Phone /> : ''}</Typography>
            </TooltipWithOffset>
            <TooltipWithOffset title={'SMS'} offset={[0, -8]}>
              <Typography variant={'body1'}>{params.row.capabilities.sms ? <Sms /> : ''}</Typography>
            </TooltipWithOffset>
            <TooltipWithOffset title={'MMS'} offset={[0, -8]}>
              <Typography variant={'body1'}>{params.row.capabilities.mms ? <Mms /> : ''}</Typography>
            </TooltipWithOffset>
          </Stack>
        );
      },
    },
    //NOTE: Conditionally render the column if the user has the required permission
    ...(HasOnePermission(['admin:twilio:buy-phone-number'])
      ? [
          {
            field: 'actions',
            headerName: 'Action',
            headerAlign: 'center' as GridAlignment,
            align: 'center' as GridAlignment,
            editable: false,
            minWidth: 100,
            renderCell: (params: GridCellParams) => {
              const isBought = boughtNumbers[params.row.phoneNumber];
              const isLoading = buyNumberLoadingState[params.row.phoneNumber];

              return (
                <React.Fragment>
                  {HasOnePermission(['admin:twilio:buy-phone-number']) && (
                    <Button
                      variant="outlined"
                      onClick={() => handleBuyNumber(params.row.phoneNumber)}
                      disabled={isLoadingBuyNumber || isBought}
                    >
                      {isLoading ? <CircularProgress size={24} /> : isBought ? <Check color={'success'} /> : 'Buy'}
                    </Button>
                  )}
                </React.Fragment>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <React.Fragment>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item sx={{ xs: { mb: '0.5rem' } }}>
          <Box>
            <AvailableTwilioNumbersSearchBar searchData={searchNumbers} />
          </Box>
        </Grid>
        <Grid item display={'flex'} alignItems="flex-end">
          <Button startIcon={<Refresh />} variant={'contained'} onClick={() => getAvailableTwilioNumbers()}>
            Refresh Phone Numbers
          </Button>
        </Grid>
      </Grid>
      <Card sx={{ mt: 3, height: '100%', overflow: 'auto' }}>
        <CardContent
          sx={{
            padding: 0,
            height: '100%',
            '&:last-child': {
              paddingBottom: 0,
            },
          }}
        >
          <Box sx={{ height: height ?? '100%', width: '100%' }}>
            <DataGrid
              loading={isLoadingAvailableTwilioNumbers}
              rows={availableTwilioNumbersData ?? []}
              disableRowSelectionOnClick
              columns={columns}
              pageSizeOptions={[30]}
              slots={{
                noRowsOverlay: () => (
                  <CustomNoRowsOverlay
                    children={
                      <React.Fragment>
                        <Numbers fontSize={'large'} />
                        <Box sx={{ mt: 1 }}>
                          {errorResults ? (
                            <>
                              <Box>
                                {'Something went wrong when retrieving available phone numbers. Please try reloading!'}
                              </Box>
                              <Box display={'flex'} justifyContent={'center'}>
                                <Button
                                  variant="text"
                                  size={'small'}
                                  startIcon={<Refresh />}
                                  onClick={() => getAvailableTwilioNumbers()}
                                >
                                  Reload
                                </Button>
                              </Box>
                            </>
                          ) : (
                            'No phone numbers available at the moment.'
                          )}
                        </Box>
                      </React.Fragment>
                    }
                  />
                ),
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

function AvailableTwilioNumbersList() {
  return (
    <NucleusPage
      title="Buy a Number"
      header="Buy a Number"
      content={
        <React.Fragment>
          <SnackbarProvider maxSnack={3} hideIconVariant preventDuplicate>
            <TwilioNumbersTable />
          </SnackbarProvider>
        </React.Fragment>
      }
    />
  );
}

export default AvailableTwilioNumbersList;
