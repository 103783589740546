import { CrmLead } from '../../types/crm/lead';
import { CrmTaskType } from '../../types/crm/taskType';
import { CrmUser } from '../../types/crm/user';
import { LeadDataType } from './Task';
import { TaskScheduleStatusEnum } from '../../types/crm/taskSchedule/taskSchedule';

export enum ActivityType {
  SMS = 'sms',
  HISTORY = 'history',
  NOTE = 'note',
  SCHEDULE = 'schedule',
  ADDRESS = 'address',
  TASK = 'task',
  EMAIL = 'email',
  DEFAULT = 'default',
  VOICE = 'voice',
}

export enum GoogleMapEditConfirmType {
  EDIT = 'EDIT',
  CONFIRM = 'CONFIRM',
}
export enum SearchFilterTaskMenuEnum {
  ALL = 'All',
  CONTACT = 'Contact',
  TASK_TYPE = 'Task type',
  CREATED_BY = 'Created by',
  CITY = 'City',
}

export type TaskScheduleHistoryType = {
  id: number;
  eventSubject: string;
  eventType: string;
  eventContextId: string;
  identity: string;
  timestamp: string;
  details: any;
  summary: string;
};

export interface TaskSchedulesRowType {
  id: number;
  taskType: CrmTaskType;
  dueDate: string;
  remarks: string;
  lead: CrmLead;
  createdBy: CrmUser;
  createdAt: string;
  taskScaffoldTypeId: number;
  taskTitle: string;
  history: TaskScheduleHistoryType[];
  status: TaskScheduleStatusEnum;
}

export type callStatusPayloadTypes = {
  status: string | undefined;
  answeredBy?: string | null | undefined;
  errorCode?: string | null | undefined;
  errorMessage?: string | null | undefined;
};

export interface ContactDetailsTaskFormProps {
  data: LeadDataType | undefined;
  taskScheduleRow: TaskSchedulesRowType | null;
}

export type BindActionToTaskType = {
  taskId: number;
};

export type CreatedTaskHistoryType = {
  assignedToId: string;
  dueDate: string;
  endTime: string;
  leadId: number;
  startTime: string;
  taskScaffoldTypeId: number;
  taskTitle: string;
  taskType: string;
  bindActionToTask: BindActionToTaskType;
  remarks: string;
};

export type AppointmentTaskHistoryType = {
  appointmentLocation: string;
  appointmentType: string;
  assignedToId: string;
  bindActionToTask: BindActionToTaskType;
  endTime: string;
  leadId: number;
  startTime: string;
  title: string;
};

export type SentEmailTaskHistoryType = {
  body: string;
  conversationSid: string;
  receiver: string;
  sender: string;
  subject: string;
};

export type ConversationTaskHistoryType = {
  accountSid: string;
  attributes: string;
  author: string;
  body: string;
  contentSid: string;
  conversationSid: string;
  dateCreated: string;
  dateUpdated: string;
  delivery: string;
  index: number;
  links: {
    channelMetadata: string;
    deliveryReceipts: string;
  };
  media: string | null;
  participantSid: string;
  sid: string;
  url: string;
};

export type NotificationType = {
  message: string;
  variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined;
};
