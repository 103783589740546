import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Modal } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { css, Global } from '@emotion/react';
import { IdentityUsers } from '../../../types/crm/user';
import { NotificationType } from '../../task/taskTypes';
import { AddressResult } from '../../../types/map';
import { GoogleMapPositionTypes } from '../../task/components/LeadMap';
import MapTaskFieldForm from '../../task/components/map/MapTaskForm';
import { useBreakpoints } from '../../../../helpers/breakpoints';
import NucleusLoader from '../../../NucleusLoader';
import { CurrentUserContext } from '../../../../contexts/CurrentUserContext';
import { CrmLead } from '../../../types/crm/lead';
import styled from '@emotion/styled';
import { TaskContactInformation } from '../../task/components/TaskContactInformation';

const modalTheme = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #8888',
  borderRadius: 2,
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  margin: 0,
  padding: 0,
  alignItems: 'stretch',
  boxSizing: 'border-box',
};

export interface ModalVoiceCallTaskTypes {
  open: boolean;
  lead: CrmLead;
  onClose: () => void;
  userIdentity?: IdentityUsers;
  handleNotification?: (notification: NotificationType) => void;
  refetchTaskList?: () => void;
}

const globalStyles = css`
  .pac-container {
    z-index: 2000;
    margin-top: 5px;
  }
`;

const ContactEdit = (props: ModalVoiceCallTaskTypes) => {
  const { open, lead, onClose } = props;
  const [propertyAddress, setPropertyAddress] = useState<String>();
  const googleMapSearchBoxInputRef = useRef<HTMLInputElement>(null);
  const googleMapInitialPosition = {
    lat: Number(lead?.address?.lat) ?? 12.9716,
    lng: Number(lead?.address?.lng) ?? 77.5946,
  };
  const [_googleMapPosition, setPosition] = useState<GoogleMapPositionTypes>(googleMapInitialPosition);
  const { isSmallScreen, isXsScreen } = useBreakpoints();
  const currentUser = useContext(CurrentUserContext);

  const handleOnUpdateAddress = (address: AddressResult) => {
    setPosition({ lat: Number(address.latitude), lng: Number(address.longitude) });
  };

  const handleOnClose = (_event: Object, reason: string) => {
    if (reason && (reason === 'backdropClick' || 'escapeKeyDown')) return;
  };

  useEffect(() => {}, [propertyAddress]);

  const handleScroll = () => {
    googleMapSearchBoxInputRef.current?.blur();
  };

  const Footer = styled(Grid)({
    height: isXsScreen ? '6rem' : '4rem', // Set a fixed height for the footer
  });

  return (
    <React.Fragment>
      <SnackbarProvider maxSnack={3} hideIconVariant preventDuplicate>
        <Modal
          open={open}
          onClose={handleOnClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <React.Fragment>
            {!currentUser && <NucleusLoader />}
            {currentUser && (
              <Grid sx={modalTheme}>
                <Grid
                  container
                  xs={12}
                  sx={{
                    backgroundColor: '#F7F9FC',
                    overflow: 'auto',
                    p: 3,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    onScroll={handleScroll}
                    sx={{ height: '100%', paddingLeft: isSmallScreen || isXsScreen ? 0 : 3 }}
                  >
                    <Global styles={globalStyles} />
                    <Grid item xs={12}>
                      <TaskContactInformation currentLead={lead} edit={true} />
                    </Grid>
                    <MapTaskFieldForm
                      ref={googleMapSearchBoxInputRef}
                      lat={12.9716}
                      lng={77.5946}
                      handleOnUpdateAddress={handleOnUpdateAddress}
                      lead={lead}
                      setPropertyAddress={setPropertyAddress}
                    />
                  </Grid>
                </Grid>

                <React.Fragment>
                  <Footer container alignItems="center" justifyContent="end" px={5} color="transparent">
                    <Grid
                      item
                      xs={12}
                      sm={1}
                      container
                      justifyContent={!isSmallScreen ? 'flex-end' : 'space-around'}
                      alignItems="center"
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Button onClick={onClose} variant="contained" color="error" size="small" sx={{ mr: 1 }}>
                          Close
                        </Button>
                      </Box>
                    </Grid>
                  </Footer>
                </React.Fragment>
              </Grid>
            )}
          </React.Fragment>
        </Modal>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default ContactEdit;
