import { Box, Button, Grid } from '@mui/material';
import React, { Context, useContext } from 'react';
import styled from '@emotion/styled';
import { ActivityType } from '../taskTypes';
import VoiceTaskFooter from './voice/VoiceTaskFooter';
import { CallStatusType } from './VoiceCallComponent';
import { CrmTaskType, TaskTypeNameType } from '../../../types/crm/taskType';
import {
  AMDContext,
  AMDContextProps,
  AMDType,
  CallStatusContext,
  CallStatusContextProps,
} from '../context/CallStatusContext';
import {
  TaskFormContext,
  TaskFormContextProps,
  TaskToCreateContext,
  TaskTypeToCreateContextProps,
} from '../context/SubmitTaskFormContext';
import { useBreakpoints } from '../../../../helpers/breakpoints';
import ContactLeadModalFooter from '../../contacts/profile/communication/ContactLeadModalFooter';
import { CrmUser } from '../../../types/crm/user';
import { VoiceTokenContext, VoiceTokenContextProps } from '../../../contexts/TwilioContext';

export const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

export interface TaskModalFooterProps {
  handleActivitySelection(activity: ActivityType): void;

  task?: boolean;
  currentUser: CrmUser | undefined;
  taskType?: CrmTaskType;
  selectedActivity?: ActivityType;
  onClose: () => void;
}

export const TaskModalFooter = (props: TaskModalFooterProps) => {
  const { handleActivitySelection, currentUser, taskType, selectedActivity, onClose, task } = props;
  const { isSmallScreen, isXsScreen } = useBreakpoints();
  const { callStatusContext, setCallStatusContext } = useContext<CallStatusContextProps>(
    CallStatusContext as Context<CallStatusContextProps>,
  );
  const { answeredByContext, setAnsweredByContext } = useContext<AMDContextProps>(
    AMDContext as Context<AMDContextProps>,
  );
  const {
    createdTaskContext,
    setSubmitTaskFormContext,
    setIsSubmittingTaskFormContext,
    setCreatedTaskContext,
    setSubmitTaskDispositionContext,
  } = useContext<TaskFormContextProps>(TaskFormContext as Context<TaskFormContextProps>);
  const { setTaskToCreateContext } = useContext<TaskTypeToCreateContextProps>(
    TaskToCreateContext as React.Context<TaskTypeToCreateContextProps>,
  );
  const { setCallInstanceContext } = useContext(VoiceTokenContext) as VoiceTokenContextProps;

  const handleEndTask = () => {
    onClose();
    setCallStatusContext(CallStatusType.Ready);
    setAnsweredByContext(AMDType.Undefined);
    setSubmitTaskFormContext({
      taskTypeFormContext: TaskTypeNameType.DEFAULT,
      submitFormContext: false,
      canSubmit: false,
    });
    setTaskToCreateContext(TaskTypeNameType.DEFAULT);
    setIsSubmittingTaskFormContext(false);
    setCreatedTaskContext({ taskType: TaskTypeNameType.DEFAULT, taskTitle: '' });
    setSubmitTaskDispositionContext({ dispositionId: null, reason: null, isSubmitting: false });
    setCallInstanceContext(null);
  };

  const handleEndTaskState = () => {
    switch (taskType?.taskTypeName) {
      case TaskTypeNameType.VOICE_CALL:
        switch (callStatusContext) {
          case CallStatusType.Ready:
          case CallStatusType.Busy:
          case CallStatusType.Failed:
          case CallStatusType.NoAnswer:
            return false;
          case CallStatusType.Ringing:
          case CallStatusType.Connecting:
          case CallStatusType.InProgress:
            return true;
          case CallStatusType.Completed:
            if (answeredByContext === AMDType.Human) {
              return !createdTaskContext;
            }
            return false;
          default:
            return false;
        }
      default:
        switch (callStatusContext) {
          case CallStatusType.Ready:
          case CallStatusType.Busy:
          case CallStatusType.Failed:
          case CallStatusType.NoAnswer:
            return false;
          case CallStatusType.Ringing:
          case CallStatusType.Connecting:
          case CallStatusType.InProgress:
            return true;
          case CallStatusType.Completed:
            if (answeredByContext === AMDType.Human) {
              return !createdTaskContext;
            }
            return false;
          default:
            return false;
        }
    }
  };

  const Footer = styled(Grid)({
    height: isXsScreen ? '6rem' : '4rem', // Set a fixed height for the footer
  });

  const TaskFooter = () => {
    if (task && task) {
      switch (taskType?.taskTypeName) {
        case TaskTypeNameType.VOICE_CALL:
          return (
            <VoiceTaskFooter
              handleActivitySelection={handleActivitySelection}
              selectedActivity={selectedActivity as ActivityType}
              currentUser={currentUser}
            />
          );
        default:
          return null;
      }
    }
    return (
      <ContactLeadModalFooter
        handleActivitySelection={handleActivitySelection}
        selectedActivity={selectedActivity as ActivityType}
      />
    );
  };

  return (
    <React.Fragment>
      <Footer container alignItems="center" justifyContent="space-around" px={5} color="transparent">
        <Grid item xs={12} sm={11} alignItems="center" justifyContent="space-evenly" flexDirection="row">
          <TaskFooter />
        </Grid>
        <Grid
          item
          xs={12}
          sm={1}
          container
          justifyContent={!isSmallScreen ? 'flex-end' : 'space-around'}
          alignItems="center"
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Button
              disabled={handleEndTaskState()}
              onClick={handleEndTask}
              variant="contained"
              color="error"
              size="small"
              sx={{ mr: 1 }}
            >
              Close
            </Button>
          </Box>
        </Grid>
      </Footer>
    </React.Fragment>
  );
};
