import { useMutation } from '@apollo/client';
import { Alert, Box, Button, Grid, IconButton } from '@mui/material';
import { Formik, FormikProps, FormikValues } from 'formik';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import React, { useContext, useRef, useState } from 'react';
import { SEND_SMS } from '../../../queries/crm/lead';
import CloseIcon from '@mui/icons-material/Close';
import { CrmLead } from '../../../types/crm/lead';
import { TaskSchedulesRowType } from '../taskTypes';
import CollapsableCard from '../../../components/CollapsableCard';
import NucleusLoader from '../../../NucleusLoader';
import {
  ProfileActivityContext,
  ProfileActivityContextProps,
} from '../../contacts/profile/context/ProfileActivityContext';
import { NucleusTextField } from '../../../components/formElements/NucleusTextField';
import { SmsErrorTypes } from '../../../types/crm/sms';
import { CustomAppoloErrorExtention } from '../../../types/crm/apolloErrorExtention';

export interface SmsTaskFormProps {
  data: CrmLead | undefined;
  taskScheduleRow?: TaskSchedulesRowType;
  fromContactProfile?: boolean;
}

interface SmsFieldFormikFieldTypes {
  message: string;
  submit: string;
}

const SmsTaskForm = (props: SmsTaskFormProps) => {
  const { data, taskScheduleRow, fromContactProfile } = props;
  const { setInitiateActivity } = useContext(ProfileActivityContext) as ProfileActivityContextProps;
  const formikRef = useRef<FormikProps<SmsFieldFormikFieldTypes>>(null);

  const [sendSmsLead] = useMutation(SEND_SMS, {
    onCompleted: _result => {
      if (formikRef.current) {
        formikRef.current.resetForm();
        formikRef.current.setErrors({ submit: '' });
        formikRef.current.setStatus({ sent: true });
        formikRef.current.setSubmitting(false);
        if (fromContactProfile) {
          setInitiateActivity(true);
        }
      }
    },
    onError: error => {
      const errorMessage = error.graphQLErrors[0].extensions as CustomAppoloErrorExtention;
      if (errorMessage.response.body.message === SmsErrorTypes.INVALID_MESSAGING_BINDING_ADDRESS) {
        enqueueSnackbar(
          'An error occurred while sending the SMS. Please verify that your phone number is correctly formatted.',
          {
            variant: 'error',
            persist: true,
            action: key => (
              <IconButton title="Close error" onClick={() => closeSnackbar(key)}>
                <CloseIcon color="disabled" />
              </IconButton>
            ),
          },
        );
      } else {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          persist: true,
          action: key => (
            <IconButton title="Close error" onClick={() => closeSnackbar(key)}>
              <CloseIcon color="disabled" />
            </IconButton>
          ),
        });
      }

      if (formikRef.current) {
        formikRef.current.setStatus({ sent: false });
        formikRef.current.setSubmitting(false);
      }
    },
  });

  const SmsInitialValues: SmsFieldFormikFieldTypes = {
    message: '',
    submit: '',
  };

  const [closeAlert, setCloseAlert] = useState(true);
  const handleClose = () => {
    setCloseAlert(false);
  };

  const timeOut = (time: number) => new Promise(res => setTimeout(res, time));

  const handleSmsSubmitForm = async (values: SmsFieldFormikFieldTypes, { setStatus, setSubmitting }: FormikValues) => {
    try {
      let input = {};

      if (taskScheduleRow) {
        input = {
          body: values.message,
          isExternal: true,
          recipientId: data?.id.toString(),
          bindActionToTask: {
            taskId: taskScheduleRow?.id,
          },
        };
      } else {
        input = {
          body: values.message,
          isExternal: true,
          recipientId: data?.id.toString(),
        };
      }

      await timeOut(1500);
      await sendSmsLead({
        variables: {
          input: input,
        },
      });
    } catch (error) {
      enqueueSnackbar('An error occurred while sending Sms', {
        variant: 'error',
        persist: true,
        action: key => (
          <IconButton title="Close error" onClick={() => closeSnackbar(key)}>
            <CloseIcon color="disabled" />
          </IconButton>
        ),
      });
      setStatus({ sent: false });
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <CollapsableCard
        sx={{
          overflow: 'unset',
        }}
        subTitle={'Send SMS'}
        body={
          <Formik
            initialValues={SmsInitialValues}
            validateOnChange={true}
            onSubmit={handleSmsSubmitForm}
            innerRef={formikRef}
          >
            {({ handleSubmit, isSubmitting, touched, errors, handleBlur, handleChange, values, resetForm, status }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} flexDirection={'column'}>
                  <Grid item>
                    <NucleusTextField
                      field="message"
                      touched={touched}
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      label={'Type your message here'}
                      values={values}
                      rows={20}
                      mention={false}
                    />
                  </Grid>
                  <Grid item flexDirection={'row'} marginTop={2}>
                    <Box display="flex" gap={3} justifyContent="space-between">
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => resetForm()}
                        disabled={isSubmitting || !values.message}
                      >
                        Clear
                      </Button>
                      {closeAlert && status && status.sent && (
                        <Alert severity="success" sx={{ width: '100%' }} onClose={handleClose}>
                          Sms sent
                        </Alert>
                      )}
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        endIcon={isSubmitting ? <NucleusLoader type={'circular'} size={20} color="inherit" /> : null}
                      >
                        {isSubmitting ? 'Sending SMS' : 'Send SMS'}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        }
      />
    </React.Fragment>
  );
};

export default SmsTaskForm;
