import { Card, CardContent, Grid, TextField, Typography, Button, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { CrmLead } from '../../../types/crm/lead';
import { Formik, Field, Form } from 'formik';
import { useMutation } from '@apollo/client';
import PhoneNumberInput from '../../../components/formElements/FormikPhoneNumberInput';
import { UPDATE_LEAD } from '../../../queries/crm/lead';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';

const TaskContactInformationGridItem = (props: {
  name: string;
  value: string;
  editable?: boolean;
  onChange?: (value: string) => void;
  fieldName?: string;
}) => {
  return (
    <React.Fragment>
      <Grid item xs={5}>
        {props.name}
      </Grid>
      <Grid item xs={7}>
        {props.editable && props.fieldName ? (
          <Field name={props.fieldName} as={TextField} fullWidth />
        ) : props.editable ? (
          <TextField fullWidth value={props.value} onChange={e => props.onChange?.(e.target.value)} />
        ) : (
          <Typography sx={{ color: '#4286de' }}>{props.value}</Typography>
        )}
      </Grid>
    </React.Fragment>
  );
};

export const TaskContactInformation = (props: { currentLead: CrmLead; edit?: boolean }) => {
  const { currentLead, edit } = props;
  const [updateLead] = useMutation(UPDATE_LEAD);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return (
    <Card style={{ borderRadius: '0px', position: 'relative', marginBottom: '1rem' }}>
      <CardContent style={{ padding: '0' }}>
        <Box sx={{ padding: '1.5rem' }}>
          <Typography variant={'h4'}>{edit ? 'Edit ' : ''}Contact Information</Typography>
          {edit ? (
            <Formik
              initialValues={{
                name: currentLead?.name || '',
                email: currentLead?.email || '',
                contactNo: currentLead?.contactNo || '',
              }}
              onSubmit={async (values, { setSubmitting }) => {
                const updatedValues = Object.keys(values).reduce((acc, key) => {
                  // @ts-ignore
                  if (values[key] !== currentLead[key]) {
                    // @ts-ignore
                    acc[key] = values[key];
                  }
                  return acc;
                }, {});

                if (Object.keys(updatedValues).length > 0) {
                  try {
                    await updateLead({
                      variables: {
                        input: {
                          ...updatedValues,
                          leadId: currentLead.id,
                        },
                      },
                    }).then(() => {
                      enqueueSnackbar('Contact updated successfully.', {
                        variant: 'success',
                        persist: true,
                        action: key => (
                          <IconButton title="Close error" onClick={() => closeSnackbar(key)}>
                            <CloseIcon color="disabled" />
                          </IconButton>
                        ),
                      });
                    });
                  } catch (error) {
                    enqueueSnackbar('There was an error updating the contact.', {
                      variant: 'error',
                      persist: true,
                      action: key => (
                        <IconButton title="Close error" onClick={() => closeSnackbar(key)}>
                          <CloseIcon color="disabled" />
                        </IconButton>
                      ),
                    });
                  }
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Grid container sx={{ my: '0rem' }} spacing={3}>
                    <TaskContactInformationGridItem name={'Name'} fieldName="name" editable={edit} value={''} />
                    <TaskContactInformationGridItem name={'Email'} fieldName="email" editable={edit} value={''} />
                    <Grid item xs={5}>
                      Contact #
                    </Grid>
                    <Grid item xs={7}>
                      <Field name="contactNo" component={PhoneNumberInput} onlyCountries={['US', 'PH']} fullWidth />
                    </Grid>
                  </Grid>
                  <Box sx={{ marginTop: '1rem' }}>
                    <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                      Save Changes
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          ) : (
            <Grid container sx={{ my: '0rem' }} spacing={3}>
              <TaskContactInformationGridItem name={'Name'} value={currentLead?.name} />
              <TaskContactInformationGridItem name={'Email'} value={currentLead?.email} />
              <TaskContactInformationGridItem name={'Contact #'} value={currentLead?.contactNo} />
              <TaskContactInformationGridItem
                name={'Address'}
                value={
                  currentLead?.address
                    ? `${currentLead?.address?.streetNo} ${currentLead?.address?.streetName} ${currentLead?.address?.city}, ${currentLead?.address?.state} ${currentLead?.address?.zipCode}`
                    : 'No address has been defined'
                }
              />
            </Grid>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
