import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Libraries, useLoadScript } from '@react-google-maps/api';
import { UPDATE_LEAD } from '../../../../queries/crm/lead';
import { MapProps } from '../../../../components/Map';
import { Alert, Box, Button, Grid } from '@mui/material';
import { Field, Formik, FormikProps, FormikValues } from 'formik';
import { AddressResult, getMapAddress } from '../../../../types/map';
import FormikGoogleMapsSearchBox from '../../../../components/formElements/FormikGoogleMapsSearchBox';
import * as Yup from 'yup';
import LeadMap, { GoogleMapPositionTypes, LeadMapProps } from '../LeadMap';
import { GoogleMapEditConfirmType } from '../../taskTypes';

interface MapFieldFormikFieldTypes {
  location: string;
  submit: string;
}

const libraries: Libraries | undefined = ['places'];

const MapTaskFieldForm = forwardRef((props: MapProps, ref) => {
  const { lead, setPropertyAddress, handleOnUpdateAddress = () => {} } = props;
  const initialPosition = {
    lat: Number(lead?.address?.lat) ?? 12.9716,
    lng: Number(lead?.address?.lng) ?? 77.5946,
  };

  const defaultAddress = `${lead?.address?.streetNo || ''} ${lead?.address?.streetName || ''} ${
    lead?.address?.city || ''
  }, ${lead?.address?.state || ''} ${lead?.address?.zipCode || ''}`;
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox>();
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [mapMarker, setMapMarker] = useState<google.maps.Marker | null>(null);
  const [position, setPosition] = useState<GoogleMapPositionTypes>(initialPosition);
  const [address, setAddress] = useState<AddressResult | null>();
  const [operationSuccess, setOperationSuccess] = useState(false);
  const [updatedAddress, setUpdatedAddress] = useState<string>(defaultAddress);
  const formikRef = useRef<FormikProps<MapFieldFormikFieldTypes>>(null);
  const [isFieldClicked, setIsFieldClicked] = useState(false);
  const buttonState = useRef<GoogleMapEditConfirmType>(GoogleMapEditConfirmType.EDIT);

  const mapValidation = Yup.object().shape({
    location: Yup.string().required('Location is required'),
  });

  useEffect(() => {
    if (lead && lead.address) {
      const currentAddress = `${lead.address.streetNo || ''} ${lead.address.streetName || ''} ${
        lead.address.city || ''
      }, ${lead.address.state || ''} ${lead.address.zipCode || ''}`;
      setUpdatedAddress(currentAddress);
      setAddress({
        country: lead.address.country,
        city: lead.address.city,
        state: lead.address.state,
        zipCode: lead.address.zipCode,
        latitude: lead.address.lat,
        longitude: lead.address.lng,
        streetNumber: lead.address.streetNo,
        streetName: lead.address.streetName,
      });
    }
  }, []);

  const MapInitialValues = {
    location: defaultAddress,
    submit: '',
  };

  const [updateLeadAddress] = useMutation(UPDATE_LEAD);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCmxw8d96uQwl80ba-ka1Vdj9H9JHddwL0',
    libraries: libraries,
  });

  const handleOnMapOnChange = (googleMapInit: google.maps.Map | null) => {
    setMap(googleMapInit);
  };

  const handleOnMapMarkerOnChange = (googleMapMarkerInit: google.maps.Marker | null) => {
    setMapMarker(googleMapMarkerInit);
  };

  const changeMapLocation = (newPosition: GoogleMapPositionTypes) => {
    setPosition(newPosition);
    if (map) {
      map.panTo(newPosition);
    }
  };

  const updateAddress = (location: { lat: number; lng: number }) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location }, (results, status) => {
      if (status === 'OK' && results?.[0]) {
        const getAddress: AddressResult = getMapAddress(results[0]);
        setAddress(getAddress);

        const formattedAddress = `${getAddress.streetNumber} ${getAddress.streetName} ${getAddress.city} ${getAddress.state} ${getAddress.zipCode} ${getAddress.country}`;
        formikRef.current?.setFieldValue('location', formattedAddress);
        setUpdatedAddress(formattedAddress);
      }
    });
  };

  const handleDragEnd = async (e: google.maps.MapMouseEvent) => {
    if (mapMarker && e && e.latLng) {
      updateAddress({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    }
  };

  const handlePlaceChanged = () => {
    const places = searchBox?.getPlaces();
    if (places?.[0]?.geometry?.location) {
      const nextCenter = {
        lat: places[0].geometry.location.lat(),
        lng: places[0].geometry.location.lng(),
      };
      updateAddress(nextCenter);
      const formattedAddress = places[0].formatted_address;
      if (formattedAddress) {
        setUpdatedAddress(formattedAddress);
      }
      changeMapLocation(nextCenter);
    }
  };

  const onSearchBoxLoad = (googleMapsSearchBoxRef: google.maps.places.SearchBox) => {
    setSearchBox(googleMapsSearchBoxRef);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps</div>;

  const handleMapSubmitForm = async (
    _values: MapFieldFormikFieldTypes,
    { setErrors, setStatus, setSubmitting }: FormikValues,
  ) => {
    if (buttonState.current === GoogleMapEditConfirmType.EDIT) {
      try {
        // await new Promise(resolve => setTimeout(resolve, 1500));
        updateLeadAddress({
          variables: {
            input: {
              leadId: lead?.id,
              address: {
                country: address?.country,
                city: address?.city,
                state: address?.state,
                streetNo: address?.streetNumber,
                streetName: address?.streetName,
                lat: address?.latitude.toString(),
                lng: address?.longitude.toString(),
                zipCode: address?.zipCode,
              },
            },
          },
        });

        handleOnUpdateAddress(address);

        if (updatedAddress && address?.city) {
          setPropertyAddress?.(address.city);
        }

        setOperationSuccess(true);
        setErrors({ submit: '' });
        setStatus({ sent: true });
        setSubmitting(false);
        setTimeout(() => {
          setOperationSuccess(false);
        }, 5000);
      } catch (error) {
        setOperationSuccess(false);
        setStatus({ sent: false });
        setErrors({ submit: 'An error occurred while updating the address.' });
        setSubmitting(false);
      }
    } else {
      setSubmitting(false); // Prevent form submission
    }
  };

  const handleClose = () => {
    setOperationSuccess(false);
  };

  const leadMapProps: LeadMapProps = {
    position: position,
    useMarker: true,
    mapContainerStyle: {
      height: '100%',
      width: '100%',
    },
    handleOnMapOnChange: handleOnMapOnChange,
    handleOnMapMarkerOnChange: handleOnMapMarkerOnChange,
    handleDragEnd: handleDragEnd,
    options: {
      streetViewControl: true,
    },
    zoom: 20,
    buttonState: buttonState.current,
    isMarkerdraggable: buttonState.current === GoogleMapEditConfirmType.EDIT ? false : true,
  };
  const handleFieldClick = () => {
    buttonState.current =
      buttonState.current === GoogleMapEditConfirmType.EDIT
        ? GoogleMapEditConfirmType.CONFIRM
        : GoogleMapEditConfirmType.EDIT;
    setIsFieldClicked(buttonState.current === GoogleMapEditConfirmType.CONFIRM);
  };

  return (
    <Box>
      <Formik
        enableReinitialize={true}
        initialValues={MapInitialValues}
        onSubmit={handleMapSubmitForm}
        innerRef={formikRef}
        validationSchema={mapValidation}
        validateOnChange={true}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
          <Box sx={{ display: 'flex-row', gap: 5 }}>
            {errors && errors.submit && <Alert severity="error">{errors.submit}</Alert>}
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                  <Box
                    sx={{
                      pt: 8,
                      position: 'absolute',
                      top: '40px',
                      width: '80%',
                      zIndex: '5',
                    }}
                  >
                    <Field
                      name="location"
                      component={FormikGoogleMapsSearchBox}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      onKeyDown={handleKeyDown}
                      value={updatedAddress}
                      handlePlaceChanged={handlePlaceChanged}
                      onSearchBoxLoad={onSearchBoxLoad}
                      innerRef={ref}
                      disabled={!isFieldClicked}
                    />
                    <Box sx={{ position: 'absolute', top: 45, right: 10, zIndex: 10 }}>
                      <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        sx={{ backgroundColor: 'white !important' }}
                        onClick={handleFieldClick}
                      >
                        {buttonState.current === GoogleMapEditConfirmType.EDIT ? 'Edit' : 'Confirm'}
                      </Button>
                    </Box>
                    <Box display="relative" alignItems="center" style={{ marginTop: '1rem' }}>
                      {operationSuccess && (
                        <Box flexGrow={1}>
                          <Alert severity="success" onClose={handleClose}>
                            The address was updated successfully!
                          </Alert>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ width: '100%', height: '71vh', position: 'relative' }}>
                    <LeadMap {...leadMapProps} />
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        )}
      </Formik>
    </Box>
  );
});
export default MapTaskFieldForm;
