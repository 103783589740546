import React, { ReactNode } from 'react';

export interface ProfileActivityContextProps {
  initiateActivity: boolean;
  setInitiateActivity: (status: boolean) => void;
}

export const ProfileActivityContext = React.createContext<ProfileActivityContextProps | undefined>(undefined);

export const ContactProfileProvider = ({ children }: { children: ReactNode }) => {
  const [initiateActivity, setInitiateActivity] = React.useState<boolean>(false);

  return (
    <ProfileActivityContext.Provider value={{ initiateActivity, setInitiateActivity }}>
      {children}
    </ProfileActivityContext.Provider>
  );
};
