import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { NotificationType } from '../../../types/conversation';
import { Alert, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { callStatusPayloadTypes } from '../taskTypes';
import { CrmUser } from '../../../types/crm/user';
import { PauseOutlined, PhoneEnabledOutlined, PlayArrow } from '@mui/icons-material';
import { useContext } from 'react';
import {
  AMDContext,
  AMDContextProps,
  AMDType,
  CallStatusContext,
  CallStatusContextProps,
} from '../context/CallStatusContext';
import { useMutation } from '@apollo/client';

import { UPDATE_TASK_SCHEDULE } from '../../../queries/crm/taskSchedule';
import {
  CrmTaskSchedule,
  CrmTaskScheduleMutationResponse,
  TaskScheduleStatusEnum,
} from '../../../types/crm/taskSchedule/taskSchedule';
import {
  ProfileActivityContext,
  ProfileActivityContextProps,
} from '../../contacts/profile/context/ProfileActivityContext';
import AlertMessage from './AlertMessage';
import { checkMicrophoneStatus } from '../../../components/MicrophoneStatus';
import { fetchAuthSession } from 'aws-amplify/auth';
import { CREATE_VOICE_CALL_RECORDING } from '../../../../common/graphQL';
import { VoiceTokenContext, VoiceTokenContextProps } from '../../../contexts/TwilioContext';
import { UserIdentityContext } from '../../../contexts/UserIdentityContext';
import { CrmCallRecordingTypeEnum } from '../../../types/crm/callRecording';

const StyledIconButton = ({
  iconColor,
  disabled,
  onClick,
  bgColor,
  children,
  tooltipTitle,
}: {
  iconColor: string;
  disabled: boolean;
  onClick: () => void;
  bgColor?: string;
  children: React.ReactNode;
  tooltipTitle: string;
}) => (
  <Tooltip title={tooltipTitle}>
    <IconButton
      sx={{
        borderRadius: 8,
        bgcolor: bgColor,
        '&:hover': {
          bgcolor: disabled ? 'gray' : 'lightgray',
        },
        ml: 1,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {children && React.isValidElement(children)
        ? React.cloneElement(children as React.ReactElement, { style: { color: iconColor } })
        : null}
    </IconButton>
  </Tooltip>
);

interface VoiceCallComponentProps {
  contactNumber: string;
  userMakingCall: CrmUser;
  taskScheduleId?: number;
  fromContactProfile?: boolean;
}

export enum DeviceStatusType {
  Closed = 'closed',
  Connecting = 'connecting',
  Open = 'open',
  Pending = 'pending',
  Reconnecting = 'reconnecting',
  Ringing = 'ringing',
}

export enum CallStatusType {
  Initiated = 'Connecting',
  Ringing = 'Ringing',
  InProgress = 'Call In Progress',
  Completed = 'Call Completed',
  Busy = 'Busy',
  NoAnswer = 'No Answer',
  Failed = 'Failed',
  Connecting = 'Connecting',
  Ready = 'Ready',
  HoldingCall = 'Putting call on hold...',
  CallOnHold = 'Call On Hold',
  ResumingCall = 'Resuming Call',
}

export const VoiceCallComponent = (props: VoiceCallComponentProps) => {
  const { userMakingCall, contactNumber, taskScheduleId, fromContactProfile } = props;
  const { setInitiateActivity } = useContext(ProfileActivityContext) as ProfileActivityContextProps;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [parentCallSid, setParentCallSid] = useState<string | undefined>(undefined);
  const [deviceStatus, setDeviceStatus] = useState<string>('closed');
  const [callDuration, setCallDuration] = useState<number>(0);
  const [callStatusPayload, setCallStatusPayload] = useState<callStatusPayloadTypes | undefined>(undefined);
  const { callStatusContext, setCallStatusContext, setCanEndTaskContext } = useContext(
    CallStatusContext,
  ) as CallStatusContextProps;
  const { setAnsweredByContext, answeredByContext } = useContext(AMDContext) as AMDContextProps;
  const [callAttempts, setCallAttempts] = useState<number>(0);
  const [recordAttempt, setRecordAttempt] = useState<number>(0);
  const [showRefreshWarning, setShowRefreshWarning] = useState<boolean>(false);
  const [microphoneInvalidPermission, setMicrophoneInvalidPermission] = useState({ show: false, message: '' });
  const [callOnHold, setCallOnHold] = useState<boolean>(false);
  const { deviceContext, setCallInstanceContext, callInstanceContext } = useContext(
    VoiceTokenContext,
  ) as VoiceTokenContextProps;
  const { userIdentity } = React.useContext(UserIdentityContext) || {};

  const [CreateCallRecording] = useMutation<{
    crm: { twilio: { call: { createRecording: { success: boolean } } } };
  }>(CREATE_VOICE_CALL_RECORDING);

  const [UpdateTaskStatus] = useMutation<CrmTaskSchedule>(UPDATE_TASK_SCHEDULE);
  const [MoveTaskDueDate] = useMutation<CrmTaskScheduleMutationResponse>(UPDATE_TASK_SCHEDULE);

  const handleNotification = ({ message, variant }: NotificationType) => {
    enqueueSnackbar(message, {
      variant: variant,
      persist: true,
      action: key => (
        <IconButton title="Close error" onClick={() => closeSnackbar(key)}>
          <GridCloseIcon color="disabled" />
        </IconButton>
      ),
    });
  };

  async function currentSession() {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      return accessToken;
    } catch (err) {
      return null;
    }
  }

  // Make an outgoing call with the device
  const makeOutgoingCall = async () => {
    setShowRefreshWarning(true); // This will show the warning message when the user tries to refresh the page
    const fetchUserIdentity = () => {
      return new Promise((resolve, reject) => {
        if (userIdentity) {
          resolve(userIdentity);
        } else {
          reject();
        }
      });
    };

    fetchUserIdentity().then(async () => {
      if (deviceContext) {
        setCallStatusContext(CallStatusType.Connecting);
        setCanEndTaskContext(false);
        const call = await deviceContext.connect({
          params: {
            to: contactNumber!,
            from: userMakingCall?.twilio?.projectedAddress,
            identity: userIdentity?.id!,
          },
        });
        call.on('disconnect', () => {
          setCallStatusContext(CallStatusType.Completed);
          setCanEndTaskContext(true);
        });
        setCallInstanceContext(call);
      }
    });
  };

  useMemo(() => {
    if (callInstanceContext) {
      setDeviceStatus(callInstanceContext.status());
      setParentCallSid(callInstanceContext.parameters.CallSid!);
    }
  }, [callInstanceContext?.status()]); //eslint-disable-line react-hooks/exhaustive-deps

  //Hold the in-progress call
  const holdCall = async () => {
    const callSid = callInstanceContext?.parameters.CallSid;
    setCallStatusContext(CallStatusType.HoldingCall);
    if (!callSid) {
      return;
    }
    try {
      await axios.post(
        '/hold-call',
        {
          callSid,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      setCallStatusContext(CallStatusType.CallOnHold);
      setCallOnHold(true);
    } catch (error) {
      handleNotification({ message: 'Call hold error. ', variant: 'error' });
    }
  };

  // Queue the on-hold call
  const resumeCall = async () => {
    const callSid = callInstanceContext?.parameters.CallSid;
    setCallStatusContext(CallStatusType.ResumingCall);
    if (!callSid) {
      return;
    }
    try {
      await axios.post(
        '/resume-call',
        {
          callSid,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      setCallStatusContext(CallStatusType.InProgress);
      setCallOnHold(false);
    } catch (error) {
      handleNotification({ message: 'Call resume error. ', variant: 'error' });
    }
  };

  const handleCheckMicrophoneStatus = async () => {
    const microphoneStatus = await checkMicrophoneStatus();

    if (microphoneStatus.status) {
      await makeOutgoingCall();
    } else {
      setMicrophoneInvalidPermission({ show: true, message: microphoneStatus.message });
    }
  };

  const handleMoveTaskDueDate = async (paramTaskScheduleId: number | undefined, notifyUser?: boolean) => {
    if (paramTaskScheduleId) {
      try {
        const moveDueDateStatus = await MoveTaskDueDate({
          variables: {
            input: {
              taskScheduleId: paramTaskScheduleId,
              moveDeadline: true,
            },
          },
        });

        if (moveDueDateStatus.data?.crm.taskSchedule.updateTaskSchedule.success && notifyUser) {
          handleNotification({
            message: 'Phone call not answered. This task was moved to the next available date.',
            variant: 'success',
          });
        }

        if (!moveDueDateStatus.data?.crm.taskSchedule.updateTaskSchedule.success) {
          handleNotification({
            message: 'No changes have been made to the deadline for this task.',
            variant: 'error',
          });
        }
      } catch (error) {
        handleNotification({
          message: 'No changes have been made to the deadline for this task.',
          variant: 'error',
        });
      }
    }
  };

  useEffect(() => {
    let fetchCallStatusInterval: NodeJS.Timeout | undefined; // Initialize fetchCallStatusInterval variable
    let timeout: NodeJS.Timeout | undefined; // Initialize timeout variable

    if (deviceStatus) {
      if (deviceStatus === 'connecting' || deviceStatus === 'open' || deviceStatus === 'ringing') {
        if (callInstanceContext?.parameters.CallSid && !callInstanceContext.parameters.CallSid.startsWith('T')) {
          setParentCallSid(callInstanceContext.parameters.CallSid!);
        }

        fetchCallStatusInterval = setInterval(() => {
          fetchCallStatus();
        }, 1000);
      }
    }

    if (deviceStatus === 'closed') {
      if (fetchCallStatusInterval) {
        clearInterval(fetchCallStatusInterval);
      }
      timeout = setTimeout(() => {
        fetchCallStatus();
      }, 3000);

      setParentCallSid(undefined);
    }

    return () => {
      clearInterval(fetchCallStatusInterval);
      clearTimeout(timeout);
    };
  }, [deviceStatus]); //eslint-disable-line react-hooks/exhaustive-deps

  // Retrieve the Call Status from the server
  async function fetchCallStatus(): Promise<void> {
    const accessToken = await currentSession();
    if (callInstanceContext?.parameters.CallSid && !callInstanceContext?.parameters.CallSid.startsWith('T')) {
      await axios
        .get(`/get-call-status?parentCallSid=${callInstanceContext?.parameters.CallSid}`, {
          headers: {
            Authorization: `${accessToken}`,
          },
        })
        .then((response: any) => {
          const callStatusData = response.data;
          setCallStatusPayload(callStatusData.callStatusResponse);
        })
        .catch(_err => {
          handleNotification({
            message: 'Something went wrong when getting the Call Status',
            variant: 'error',
          });
        });
    }
  }

  // Disconnect the call
  const handleDisconnectCall = async () => {
    setShowRefreshWarning(false); // This will hide the warning message when the call is disconnected
    if (deviceContext) {
      deviceContext.disconnectAll();
      setCallDuration(0);

      if (!parentCallSid) {
        setCallStatusContext(CallStatusType.Ready);
        setCanEndTaskContext(true);
      }

      if (callInstanceContext?.parameters?.CallSid && !callInstanceContext?.parameters?.CallSid.startsWith('T')) {
        setTimeout(() => {
          fetchCallStatus();
        }, 2000);
      }
    }
  };

  useEffect(() => {
    if (callStatusContext === CallStatusType.Ringing) {
      return setCallAttempts(prevAttempts => prevAttempts + 1);
    }
  }, [callStatusContext]); // eslint-disable-line react-hooks/exhaustive-deps

  useMemo(() => {
    if (callStatusContext === CallStatusType.Completed && answeredByContext === AMDType.Human) {
      if (recordAttempt < 1) {
        CreateCallRecording({
          variables: {
            input: {
              callSid: callInstanceContext?.parameters.CallSid,
              receiver: contactNumber!,
              type: CrmCallRecordingTypeEnum.CALL,
            },
          },
        }).then(() => {
          if (fromContactProfile) {
            setInitiateActivity(true);
          }
        });
        if (taskScheduleId) {
          UpdateTaskStatus({
            variables: {
              input: {
                taskScheduleId: taskScheduleId!,
                status: TaskScheduleStatusEnum.IN_PROGRESS,
              },
            },
          });
        }
        setRecordAttempt(prevRecordAttempt => prevRecordAttempt + 1);
      }
    }
  }, [callStatusContext, answeredByContext, recordAttempt]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const status = callStatusPayload?.status;
    const answeredBy = callStatusPayload?.answeredBy;

    let fetchCallStatusInterval: NodeJS.Timeout | null = null; // Initialize to null

    const setCallStatusFc = async () => {
      switch (status) {
        case 'initiated':
          setCallStatusContext(CallStatusType.Initiated);
          break;
        case 'ringing':
          setCallStatusContext(CallStatusType.Ringing);
          break;
        case 'in-progress':
          if (taskScheduleId) {
            if (answeredBy !== undefined || true) {
              if (answeredBy === 'human') {
                setCallStatusContext(CallStatusType.InProgress);
              } else {
                await handleDisconnectCall(); // disconnect the call if it's not answered by human
                setCallStatusContext(CallStatusType.Completed);
                setCanEndTaskContext(true);
                return;
              }
              setCallStatusContext(CallStatusType.InProgress);
              setAnsweredByContext(answeredBy as AMDType);
            }
          } else {
            if (answeredBy !== undefined || true) {
              setCallStatusContext(CallStatusType.InProgress);
              setAnsweredByContext(answeredBy as AMDType);
            }
          }
          break;
        case 'completed':
          // Start the loop only if answeredBy is falsy
          if (!answeredBy) {
            fetchCallStatusInterval = setInterval(() => {
              fetchCallStatus();

              // Check if answeredBy has a value, clear the interval
              if (answeredBy) {
                setAnsweredByContext(answeredBy as AMDType);
              }
            }, 2000);
          }

          // Check the value of answeredBy and set call status accordingly
          if (answeredBy === 'human') {
            setAnsweredByContext(answeredBy as AMDType);
            setCallStatusContext(CallStatusType.Completed);
            setCanEndTaskContext(true);
          } else {
            // If answeredBy is unknown
            setAnsweredByContext(answeredBy as AMDType);
            setCallStatusContext(CallStatusType.Completed);
            setCanEndTaskContext(true);
          }
          handleMoveTaskDueDate(taskScheduleId);
          break;
        case 'busy':
          setCallStatusContext(CallStatusType.Busy);
          setCanEndTaskContext(true);
          handleMoveTaskDueDate(taskScheduleId, true);
          break;
        case 'no-answer':
          setCallStatusContext(CallStatusType.NoAnswer);
          setCanEndTaskContext(true);
          handleMoveTaskDueDate(taskScheduleId, true);
          break;
        case 'failed':
          setCallStatusContext(CallStatusType.Failed);
          setCanEndTaskContext(true);
          handleMoveTaskDueDate(taskScheduleId, true);
          break;
        default:
          break;
      }
    };

    setCallStatusFc();

    return () => {
      if (answeredBy) {
        clearInterval(fetchCallStatusInterval!);
      }
    };
  }, [callStatusPayload?.status, callStatusPayload?.answeredBy]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (callStatusContext === CallStatusType.InProgress) {
      interval = setInterval(() => {
        setCallDuration(prevDuration => prevDuration + 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [callStatusContext]);

  const formatCallDuration = (duration: number): string => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = duration % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  const isStatusReady = () => {
    if (taskScheduleId) {
      return (
        (callStatusContext === CallStatusType.Ready ||
          callStatusContext === CallStatusType.NoAnswer ||
          callStatusContext === CallStatusType.Busy ||
          answeredByContext === AMDType.Machine ||
          answeredByContext === AMDType.Unknown) &&
        callAttempts < 2
      );
    }
    return (
      callStatusContext === CallStatusType.Ready ||
      callStatusContext === CallStatusType.Completed ||
      callStatusContext === CallStatusType.Busy ||
      callStatusContext === CallStatusType.NoAnswer
    );
  };

  const RefreshWarning = () => {
    useEffect(() => {
      const handleBeforeUnload = (event: { preventDefault: () => void; returnValue: string }) => {
        // Cancel the event
        event.preventDefault();
      };
      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        // Clean up the event listener when component unmounts
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, []);
    return (
      <React.Fragment>
        <Grid container px={3}>
          <AlertMessage
            setShow={setShowRefreshWarning}
            message={'Warning: Please refrain from refreshing this page while the call is in progress.'}
            severity={'warning'}
          />
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {showRefreshWarning && <RefreshWarning />}
      <Grid container p={3}>
        <Grid item xs={2} alignContent={'center'}>
          <Typography sx={{ color: 'gray' }} display="inline">
            {formatCallDuration(callDuration)}
          </Typography>
        </Grid>
        <Grid item xs={8} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Typography style={{ color: 'green' }}>{callStatusContext}</Typography>
        </Grid>
        <Grid item xs={2} display={'flex'} justifyContent={'right'}>
          {isStatusReady() && (
            <StyledIconButton
              onClick={handleCheckMicrophoneStatus}
              disabled={callAttempts > 1}
              iconColor={callAttempts > 1 ? 'gray' : 'green'}
              tooltipTitle={'Start Call'}
            >
              <PhoneEnabledOutlined style={{}} />
            </StyledIconButton>
          )}
          {!isStatusReady() && (
            <React.Fragment>
              {callStatusContext === CallStatusType.InProgress &&
                (callOnHold ? (
                  <StyledIconButton
                    onClick={resumeCall}
                    disabled={false}
                    iconColor={callStatusContext === CallStatusType.InProgress ? 'white' : 'gray'}
                    bgColor={callStatusContext === CallStatusType.InProgress ? 'gray' : 'lightgray'}
                    tooltipTitle={'Resume'}
                  >
                    <PlayArrow />
                  </StyledIconButton>
                ) : (
                  <StyledIconButton
                    onClick={holdCall}
                    disabled={false}
                    iconColor={callStatusContext === CallStatusType.InProgress ? 'white' : 'gray'}
                    bgColor={callStatusContext === CallStatusType.InProgress ? 'gray' : 'lightgray'}
                    tooltipTitle={'Hold'}
                  >
                    <PauseOutlined />
                  </StyledIconButton>
                ))}
              <StyledIconButton
                onClick={handleDisconnectCall}
                disabled={
                  callStatusContext !== CallStatusType.InProgress &&
                  callStatusContext !== CallStatusType.Ringing &&
                  callStatusContext !== CallStatusType.Initiated
                }
                iconColor={
                  callStatusContext !== CallStatusType.InProgress &&
                  callStatusContext !== CallStatusType.Ringing &&
                  callStatusContext !== CallStatusType.Initiated
                    ? 'gray'
                    : 'white'
                }
                bgColor={
                  callStatusContext !== CallStatusType.InProgress &&
                  callStatusContext !== CallStatusType.Ringing &&
                  callStatusContext !== CallStatusType.Initiated
                    ? 'lightgray'
                    : 'red'
                }
                tooltipTitle={'End call'}
              >
                <PhoneEnabledOutlined />
              </StyledIconButton>
            </React.Fragment>
          )}
        </Grid>
        {microphoneInvalidPermission.show && (
          <Alert
            variant="filled"
            severity="error"
            sx={{ width: '100%' }}
            onClose={() => {
              setMicrophoneInvalidPermission({ show: false, message: '' });
            }}
          >
            {microphoneInvalidPermission.message}
          </Alert>
        )}
      </Grid>
    </React.Fragment>
  );
};
